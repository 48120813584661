import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; // Import useLocation
interface MetaInformation {
  metaInfoTitle?: string;
  metaInfoDescription?: string;
  metaInfoKeyword?: string | string[];
}

// In utils/Helmet.ts
export const RenderHelmetForCat = ({
  metaInformations,
}: {
  metaInformations: MetaInformation;
}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <Helmet>
        <title>{metaInformations?.metaInfoTitle}</title>

        {metaInformations?.metaInfoDescription && (
          <meta
            name="description"
            content={metaInformations?.metaInfoDescription}
          />
        )}
        {metaInformations?.metaInfoKeyword && (
          <meta
            name="keywords"
            content={
              Array.isArray(metaInformations?.metaInfoKeyword)
                ? metaInformations?.metaInfoKeyword?.join(",")
                : metaInformations?.metaInfoKeyword
            }
          />
        )}
        <link
          rel="canonical"
          href={`https://www.cayroshop.com${currentPath}`}
        />
      </Helmet>
    </>
  );
};

export const RenderHelmetForProduct = ({
  metaInformations,
}: {
  metaInformations: MetaInformation;
}) => {
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  return (
    <>
      <Helmet>
        <title>{metaInformations?.metaInfoTitle}</title>

        {metaInformations?.metaInfoDescription && (
          <meta
            name="description"
            content={metaInformations?.metaInfoDescription}
          />
        )}
        {metaInformations?.metaInfoKeyword && (
          <meta
            name="keywords"
            content={
              Array.isArray(metaInformations?.metaInfoKeyword)
                ? metaInformations?.metaInfoKeyword?.join(",")
                : metaInformations?.metaInfoKeyword
            }
          />
        )}
        <link
          rel="canonical"
          href={`https://www.cayroshop.com${currentPath}`}
        />
      </Helmet>
    </>
  );
};

export const RenderHelmetForSubCat = ({
  metaInformations,
}: {
  metaInformations: MetaInformation;
}) => {
  const location = useLocation();
  const currentPath = location.pathname + location.search;

  return (
    <>
      <Helmet>
        <title>{metaInformations?.metaInfoTitle}</title>

        {metaInformations?.metaInfoDescription && (
          <meta
            name="description"
            content={metaInformations?.metaInfoDescription}
          />
        )}
        {metaInformations?.metaInfoKeyword && (
          <meta
            name="keywords"
            content={
              Array.isArray(metaInformations?.metaInfoKeyword)
                ? metaInformations?.metaInfoKeyword?.join(",")
                : metaInformations?.metaInfoKeyword
            }
          />
        )}
        <link
          rel="canonical"
          href={`https://www.cayroshop.com${currentPath}`}
        />
      </Helmet>
    </>
  );
};

export const RenderHelmetForBrand = ({
  metaInformations,
}: {
  metaInformations: any;
}) => {
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  console.log(metaInformations);
  return (
    <>
      <Helmet>
        <title>{metaInformations?.metaInfo?.metaInfoTitle}</title>

        {metaInformations?.metaInfo?.metaInfoDescription && (
          <meta
            name="description"
            content={metaInformations?.metaInfo?.metaInfoDescription}
          />
        )}
        {metaInformations?.metaInfo?.metaInfoKeyword && (
          <meta
            name="keywords"
            content={
              Array.isArray(metaInformations?.metaInfo?.metaInfoKeyword)
                ? metaInformations?.metaInfo?.metaInfoKeyword?.join(",")
                : metaInformations?.metaInfo?.metaInfoKeyword
            }
          />
        )}
        <link
          rel="canonical"
          href={`https://www.cayroshop.com${currentPath}`}
        />
      </Helmet>
    </>
  );
};
