const StoreImage = () => {
  return (
    <>
      <div className="flex md:flex-col w-full h-[90vh] md:h-auto mt-2 gap-2 items-center justify-evenly flex-wrap">
        {/* Large Image */}
        <div className="w-[30%] h-[80%] md:w-full md:h-[50%]">
          <img
            src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1740051586151_1740051586145.jpg"
            alt="Store 3"
            className="w-full h-full object-cover"
            loading="lazy"
          />
        </div>

        {/* Middle Images */}
        <div className="flex flex-col gap-2 w-[30%] h-[80%] md:flex-row md:w-full">
          <img
            src="https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741338957569_1741338957566.webp"
            alt="Store 2"
            className="w-full md:w-[50%] h-[50%] object-cover"
            loading="lazy"
          />
          <img
            src="https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741339074876_1741339074873.webp"
            alt="Store 1"
            className="w-full md:w-[50%] h-[50%] object-cover md:h-auto object-center"
            loading="lazy"
          />
        </div>

        {/* Right Image */}
        <div className="w-[30%] h-[80%] md:w-full md:h-[50%]">
          <img
            src="https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741339103567_1741339103563.webp"
            alt="Store 4"
            className="w-full h-full object-cover"
            loading="lazy"
          />
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="bg-amber-100 text-center p-4 mt-4">
        <p className="uppercase tracking-widest font-lighter text-lg">
          Visit Our Showroom & Get upto 25% Discount on Your First Purchase!
        </p>
      </div>
    </>
  );
};

export default StoreImage;
