import React from "react";
import { Helmet } from "react-helmet";

const About: React.FC = () => {
  return (
    <div className="container mx-auto px-4 mt-[5rem] sm:mt-[8rem]">
      {/* Helmet for setting the page title */}
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="w-full h-full ">
        <div className="flex flex-wrap items-center p-6 sm:p-4">
          {/* Left Section */}
          <div className="flex flex-col w-[50%] sm:w-full gap-6 sm:gap-4">
            <h1 className="text-5xl sm:text-3xl md:text-4xl font-bold text-left leading-tight">
              Welcome to cayroshop.com
            </h1>
            <p className="text-lg sm:text-sm md:text-base text-gray-700">
              Your premier destination for all things electronic and
              appliance-related. At CayroShop, we believe in providing our
              customers with the latest and greatest in technology, delivered
              with unparalleled customer service and satisfaction.
            </p>
          </div>

          {/* Right Section */}
          <div className="w-[50%] sm:w-full mt-6 sm:mt-4">
            <img
              src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967632868_1739967632791.jpg"
              alt="About Us"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-between w-full p-8 sm:p-4">
          {/* Left Section */}
          <div className="w-[50%] sm:w-full text-center sm:text-left mb-4 sm:mb-6">
            <h1 className="text-5xl sm:text-3xl md:text-4xl font-bold leading-tight">
              Our Mission
            </h1>
          </div>

          {/* Right Section */}
          <div className="w-[50%] sm:w-full">
            <p className="text-lg sm:text-base text-gray-700">
              At CayroShop, our mission is simple - to revolutionize your
              shopping experience by offering an extensive selection of
              top-quality products, competitive prices, and exceptional customer
              service. We strive to be your one-stop destination for all your
              electronic and appliance needs, making shopping convenient,
              enjoyable, and hassle-free.
            </p>
          </div>
        </div>

        <div className="flex sm:flex-col sm:w-full gap-6 p-4 mt-[50px]">
          {/* Kitchen Appliances Card */}
          <div className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-300">
            <img
              src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967371451_1739967371449.png"
              alt="Kitchen Appliances"
              className="w-[50px] h-[50px] mx-auto "
            />
            <h1 className="text-lg font-semibold text-center mt-4 text-blue-800">
              Kitchen Appliances
            </h1>
            <p className="text-sm text-gray-600 mt-2 text-center leading-6 ">
              Transform your kitchen into a culinary haven with our selection of
              kitchen appliances, including blenders, coffee makers, toasters,
              and more, designed to simplify meal preparation and cooking.
            </p>
          </div>

          {/* Television Card */}
          <div className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-300">
            <img
              src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967527723_1739967527720.png"
              alt="Television"
              className="w-[50px] h-[50px] mx-auto"
            />
            <h1 className="text-lg font-semibold text-center mt-4 text-blue-800">
              Television
            </h1>
            <p className="text-sm text-gray-600 mt-2 text-center leading-6">
              Immerse yourself in the ultimate viewing experience with our wide
              selection of high-definition televisions, ranging from LED to OLED
              and beyond.
            </p>
          </div>

          {/* Audio Card */}
          <div className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-300">
            <img
              src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966956089_1739966956086.png"
              alt="Audio"
              className="w-[50px] h-[50px] mx-auto"
            />
            <h1 className="text-lg font-semibold text-center mt-4 text-blue-800">
              Audio
            </h1>
            <p className="text-sm text-gray-600 mt-2 text-center leading-6">
              Elevate your audio experience with our collection of speakers,
              headphones, soundbars, and more, designed to deliver crystal-clear
              sound quality.
            </p>
          </div>

          {/* Home Appliances Card */}
          <div className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-300">
            <img
              src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967254872_1739967254871.png"
              alt="Home Appliances"
              className="w-[50px] h-[50px] mx-auto"
            />
            <h1 className="text-lg font-semibold text-center mt-4 text-blue-800">
              Home Appliances
            </h1>
            <p className="text-sm text-gray-600 mt-2 text-center leading-6">
              Make life easier with our range of home appliances, including
              refrigerators, washing machines, air conditioners, and more, from
              trusted brands known for their reliability and performance.
            </p>
          </div>
        </div>

        <div className="flex flex-col  items-center py-8 bg-gray-50">
          <h1 className="text-4xl font-bold text-gray-800 mb-8">
            Our Products
          </h1>
          <div className="flex sm:flex-col gap-8 w-full px-4 ">
            {/* Card 1 */}
            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img
                src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967008939_1739967008938.png"
                alt="Computers"
                className="w-16 h-16 mb-4"
              />
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Computers
              </h2>
              <p className="text-gray-600 text-sm leading-6">
                Whether you're a professional or a casual user, we have the
                perfect computing solutions for you, including laptops,
                desktops, and accessories to enhance your productivity.
              </p>
            </div>

            {/* Card 2 */}
            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img
                src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966876956_1739966876954.png"
                alt="Accessories"
                className="w-16 h-16 mb-4"
              />
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Accessories
              </h2>
              <p className="text-gray-600 text-sm leading-6">
                Find the perfect accessories to complement your devices, from
                protective cases and chargers to cables and adapters.
              </p>
            </div>

            {/* Card 3 */}
            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img
                src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967390474_1739967390473.png"
                alt="Mobiles & Tablets"
                className="w-16 h-16 mb-4"
              />
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Mobiles & Tablets
              </h2>
              <p className="text-gray-600 text-sm leading-6">
                Stay connected and productive with the latest smartphones and
                tablets from leading brands.
              </p>
            </div>

            {/* Card 4 */}
            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img
                src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967418833_1739967418831.png"
                alt="Personal Care"
                className="w-16 h-16 mb-4"
              />
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Personal Care
              </h2>
              <p className="text-gray-600 text-sm leading-6">
                Take care of yourself with our range of personal care products,
                including grooming essentials, beauty tools, and health
                monitors, to help you look and feel your best every day.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start justify-center mt-[40px]  ml-[100px] sm:ml-0">
          <img
            src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967492666_1739967492665.png"
            className="ml-[100px] w-[60px] sm:ml-[50px]"
            alt=""
          />
          <h1 className="text-[40px] sm:ml-[50px] mt-[40px] ml-[100px]">
            Why Choose CayroShop?
          </h1>

          <div className="flex gap-[30px] flex-col ml-[100px] mt-[30px] sm:ml-[50px]">
            <div className="que">
              <div className="head flex items-center gap-[20px]">
                <img src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967471822_1739967471821.png" alt="" />
                <h2>Quality Assurance</h2>
              </div>
              <h3>
                We source our products from reputable manufacturers to ensure
                superior quality and reliability
              </h3>
            </div>

            <div className="que">
              <div className="head flex items-center gap-[20px]">
                <img src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967070862_1739967070851.png" alt="" />
                <h2>Competitive Pricing </h2>
              </div>
              <h3>
                Enjoy competitive prices on all our products, allowing you to
                get more value for your money
              </h3>
            </div>

            <div className="que">
              <div className="head flex items-center gap-[20px]">
                <img src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967601850_1739967601839.png" alt="" />
                <h2>Fast Shipping </h2>
              </div>
              <h3>
                We offer fast and reliable shipping options to ensure your order
                reaches you in a timely manner
              </h3>
            </div>

            <div className="que">
              <div className="head flex items-center gap-[20px]">
                <img src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1739967114806_1739967114804.png" alt="" />
                <h2>Dedicated Customer Support </h2>
              </div>
              <h3>
                Our friendly and knowledgeable customer support team is here to
                assist you with any questions or concerns you may have
              </h3>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center items-center mt-[40px]">
          <div className="flex  sm:flex-col gap-6 w-[90%] sm:w-full mt-[40px] items-center bg-sky-100 rounded-full p-6 sm:p-4 sm:rounded-lg  shadow-lg">
            {/* Left Section */}
            <div className="sm:h-[250px] sm:rounded-[10px] sm:border-b-blue-300 sm:border-2 w-[25%] sm:w-full sm:w-full h-full sm:h-auto flex items-center bg-white rounded-tl-full rounded-bl-full rounded-l-lg sm:rounded-lg shadow-lg p-6 sm:p-4 sm:block">
              <h1 className="mt-[70px] text-blue-600  h-[200px] sm:text-center text-[30px] sm:text-lg md:text-2xl lg:text-3xl font-semibold text-center">
                Shop with Confidence
              </h1>
            </div>

            {/* Right Section */}
            <div className="w-[65%] sm:w-full">
              <h4 className="leading-[30px] sm:leading-5 text-base sm:text-sm md:text-lg sm:pb-[10px]">
                Shop with confidence at CayroShop and experience the convenience
                of online shopping combined with the reliability of a trusted
                retailer. Whether you're upgrading your gadgets, enhancing your
                entertainment setup, or outfitting your home with the latest
                appliances, CayroShop has you covered. Explore our website today
                and discover a world of possibilities at your fingertips.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
