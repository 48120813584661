import React, { useState, useRef } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addToWishlist, removeFromWishlist } from "../../slice/WishlistSlice";
import { AppDispatch, RootState } from "../../store/store";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { asyncAddToCart, asyncUpdateQuantity } from "../../actions/productAction";
import { FiMinus, FiPlus } from "react-icons/fi";
import Loader from "../../common/loader/Loader";
import { asyncRemoveItem } from "../../actions/searchProductAction";


const BadaDiscount = (props: any) => {
  let { products, heading, relatedProductPage } = props;
  const [loadingProductId, setLoadingProductId] = useState<string | null>(null);
  const [cart, setCart] = useState<{ [key: string]: number }>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const userIsLoggedIn = useSelector((state: RootState) => state?.user.isLoggedIn);
  // New state to track loading for each product
  const [loadingProducts, setLoadingProducts] = useState<{
    [key: string]: boolean;
  }>({});

  const calculateDiscountPercentage = (
    salePrice: number,
    regularPrice: number
  ) => {
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };

  // Updated handleAddToCart function
  const handleAddToCart = async (product: any, index: number) => {
    const productId = product._id;
    setLoadingProductId(productId);
    setLoadingProducts((prev) => ({ ...prev, [productId]: true }));
    let flag=true
    try {
      await dispatch(asyncAddToCart(product,flag));

      // Update loading state after cart is updated
      setLoadingProducts((prev) => ({ ...prev, [productId]: false }));

      // Optionally set cart state if required (for UI updates)
      setCart((prevCart) => ({ ...prevCart, [productId]: 1 }));
    } catch (error) {
      console.error("Failed to add product to cart:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  const handleUpdateQuantity = async (product: any, index: number, quantity: number) => {
    const productId = product._id;
    setLoadingProductId(productId);

    try {
      if (quantity === 0) {
        await dispatch(asyncRemoveItem(product));
        setCart((prevCart: any) => ({ ...prevCart, [productId]: quantity }));
      } else {
        await dispatch(asyncUpdateQuantity(product, index, quantity));
        setCart((prevCart: any) => ({ ...prevCart, [productId]: quantity }));
      }
    } catch (error) {
      console.error("Failed to update quantity:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  // const generateProductStructuredData = (products: any) => {
  //   if (!products || products.length === 0) return null;

  //   // Get the current domain (protocol + domain + port if needed)
  //   const baseUrl = window.location.origin; // This gets the domain where the site is hosted

  //   return products.map((product: any) => {
  //     const structuredData = {
  //       "@context": "https://schema.org/",
  //       "@type": "Product",
  //       name: product.productName || "Product Name",
  //       image: product.images[0]?.url || "default-product-image.jpg", // First image URL
  //       description: `Buy ${product.productName} at discounted price from our store.`,
  //       sku: product._id || "Product SKU",
  //       offers: {
  //         "@type": "Offer",
  //         url: `${baseUrl}/product?name=${product.productName}&product_id=${product._id}`, // URL to the product page on your domain
  //         priceCurrency: "INR", // Assuming price is in INR
  //         price: product.salePrice || product.regularPrice,
  //         priceValidUntil: "2024-12-31", // Optional, set a date for offer validity
  //         itemCondition: "https://schema.org/NewCondition",
  //         availability:
  //           product.totalStock > 0
  //             ? "https://schema.org/InStock"
  //             : "https://schema.org/OutOfStock",
  //         eligibleRegion: {
  //           "@type": "Place",
  //           name: "India",
  //         },
  //       },
  //       aggregateRating: {
  //         "@type": "AggregateRating",
  //         ratingValue: "4.5", // You can update this based on reviews
  //         reviewCount: "100", // Update with actual review count
  //       },
  //     };

  //     return (
  //       <script key={product._id} type="application/ld+json">
  //         {JSON.stringify(structuredData)}
  //       </script>
  //     );
  //   });
  // };
  // const productStructuredData = generateProductStructuredData(products);



  return (
    <div
      className="sm:pt-[0.5rem] p-4 ml-[40px] no-scrollbar mt-[1rem] w-[70%] lg:w-auto lg:ml-0
      overflow-x-auto scrollbar-thin scrollbar-thumb-rounded
      relative"
    >
          {/* <Helmet>{productStructuredData}</Helmet> */}

      <button
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        onClick={scrollRight}

        className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowRight />
      </button>
      <div
        ref={containerRef}
        className="flex p-2 md:p-0 overflow-x-auto no-scrollbar mt-[1rem] flex-row"
        style={{ overflowX: "scroll" }} // Hide the scrollbar
      >
      {products?.length > 0 &&
  products.map((product: any, index: number) => {
    const productId = product._id;
    const quantity = cart[productId] || 0;

    const tagMap: any = {
      trending: {
        label: "Trending",
        color:
          "text-center text-xs sm:text-sm bg-gradient-to-r from-green-400 to-green-600",
      },
      latest: {
        label: "Latest",
        color:
          "text-center text-xs sm:text-sm bg-gradient-to-r from-yellow-400 to-yellow-600",
      },
      hotDeals: {
        label: "Hot Deals",
        color:
          "text-center text-xs sm:text-sm bg-gradient-to-r from-red-400 to-red-600",
      },
      dailyDeals: {
        label: "Daily Deals",
        color:
          "text-center text-xs sm:text-sm bg-gradient-to-r from-purple-400 to-purple-600",
      },
      newLaunches: {
        label: "Newly",
        color:
          "text-center text-xs sm:text-sm bg-gradient-to-r from-sky-400 to-sky-600",
      },
    };

    let tag = Object.keys(tagMap).find((key) => product[key]);
    const tagDetails = tag ? tagMap[tag] : null;

    return (
      <div
        className=" lg:mt-[17rem] item flex-none m-[1rem] mt-[5rem] bg-white sm:basis-[40%] sm:m-[0.4rem] basis-[20%] flex-shrink-0 p-2 sm:p-[0.5rem] transform transition-transform duration-300 ease-in-out relative rounded-lg flex flex-col justify-between"
        style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
        key={index}
      >
        {/* Product Tag */}
        {tagDetails && (
          <span
            className={`w-[80px] justify-center h-6 inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 ${tagDetails.color} 
              text-white font-medium rounded-full shadow-lg transform transition-all duration-300 ease-in-out 
              hover:rotate-0 hover:scale-105 whitespace-nowrap`}
          >
            {tagDetails.label}
          </span>
        )}

        <Link
          to={`/product?name=${product.productName
            .replace(/[^a-zA-Z0-9]/g, "-")
            .replace(/-+/g, "-")
            .toLowerCase()}&product_id=${product._id || product.id}`}
          className="flex mt-[0.9rem] justify-center items-center"
        >
          <LazyLoadImage
            effect="blur"
            src={product?.images[0]?.url}
            alt={`Carousel item ${index + 1}`}
            className="w-full h-[220px] !flex justify-center items-center sm:h-[100px] object-contain rounded-lg transition-transform duration-300 transform hover:scale-105"
          />
        </Link>

        {/* Wishlist Button */}
        {heading !== "Related Products" && (
          <div className="absolute cursor-pointer top-[7px] sm:top-0 right-0 sm:right-[1px] text-red-500 sm:text-[1rem] px-2 py-1 text-2xl font-bold hover:bg-red">
            {!product.addedToWishlist ? (
              <IoMdHeartEmpty
                onClick={async () => {
                  if (userIsLoggedIn) {
                    await dispatch(addToWishlist(product));
                  } else {
                    navigate("/login-by-otp");
                  }
                }}
                className="text-red hover:fill-red"
              />
            ) : (
              <IoMdHeart
                onClick={async () => {
                  if (userIsLoggedIn) {
                    await dispatch(removeFromWishlist(product));
                  } else {
                    navigate("/login-by-otp");
                  }
                }}
              />
            )}
          </div>
        )}

        {/* Product Details */}
        <div className="text-center flex-grow">
          <h3 className="text-[#f55a01] text-lg sm:text-[0.78rem] font-semibold">
            ₹{product.salePrice}
          </h3>
          <h3 className="line-through sm:block text-[0.75rem] ml-[0.3rem] text-gray-500">
            ₹{product.regularPrice}
          </h3>
          <h4
            className={
              relatedProductPage
                ? "text-gray-900 mt-[0.35rem] mb-[0.35rem] sm:my-[0.2rem] text-[0.9rem] font-[450] sm:text-xs sm:font-[400] line-clamp-4"
                : "text-gray-900 mt-[0.35rem] mb-[0.35rem] sm:my-[0.2rem] text-[0.9rem] font-[450] sm:text-xs sm:font-[400] line-clamp-2"
            }
          >
            <Link
              to={`/product?name=${product.productName}&product_id=${product._id}`}
            >
              {product.productName}
            </Link>
          </h4>
          <span className="!text-[#CD0B39] sm:!text-[12px] font-semibold ml-[0.3rem] !text-md ">
            {calculateDiscountPercentage(
              Number(product?.salePrice),
              Number(product?.regularPrice)
            ) + "% off"}
          </span>
        </div>

        {/* Stock and Cart Controls */}
        {product.totalStock === 0 ? (
          <div className="flex justify-center items-center cursor-not-allowed w-full sm:my-[0.5rem]">
            <button className="bg-gray-400 text-white text-center w-3/4 sm:h-[25px] relative rounded-lg p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] cursor-not-allowed">
              Out Of Stock
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-5 justify-center w-full">
            {quantity > 0 ? (
              <div className="flex items-center justify-between w-3/4 rounded-lg p-1 mt-[20px]">
                <button
                  onClick={() =>
                    handleUpdateQuantity(product, index, quantity - 1)
                  }
                  className="p-1 rounded-full border bg-gray-200 hover:bg-gray-300 transition disabled:opacity-50"
                  disabled={loadingProductId === productId}
                >
                  <FiMinus size={16} />
                </button>
                <span className="text-lg font-semibold">{quantity}</span>
                <button
                  onClick={() =>
                    handleUpdateQuantity(product, index, quantity + 1)
                  }
                  className="p-1 rounded-full border bg-gray-200 hover:bg-gray-300 transition disabled:opacity-50"
                  disabled={
                    loadingProductId === productId ||
                    quantity >= product.totalStock
                  }
                >
                  <FiPlus size={16} />
                </button>
              </div>
            ) : loadingProductId === productId ? (
              <div className="flex items-center justify-center absolute bottom-[15%]">
                <Loader />
              </div>
            ) : (
              <button
                onClick={() => handleAddToCart(product, index)}
                className="mt-[20px] flex items-center justify-center gap-2 bg-indigo-700 text-white w-3/4 relative rounded-lg p-1 whitespace-nowrap text-sm md:text-[12px] hover:bg-indigo-800 transition disabled:opacity-50"
                disabled={loadingProductId === productId}
              >
                <span>Add to Cart</span>
                <FaShoppingCart size={15} />
              </button>
            )}
          </div>
        )}
      </div>
    );
  })}

      </div>
      <button
        onClick={scrollLeft}
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        className="absolute left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowLeft />
      </button>
    </div>
  );
};

export default BadaDiscount;
