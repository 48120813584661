import { FaAngleRight, FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

interface UpperBannerProps {
  categoryName: string | null;
  subcategoryName: string | null;
  brands: { _id: string; name: string }[];
  brandName: string | null;
  bannerImage: string;
}

const UpperBanner = ({
  categoryName,
  subcategoryName,
  brands,
  bannerImage,
  brandName,
}: UpperBannerProps) => {
  const formatText = (text: string | null) => (text ? text.replace(/-/g, " ") : "");

  return (
    <>
      {/* Breadcrumb Navigation */}
      <div className="text-sm rounded-md w-fit mt-[95px] ml-2 p-[1px] bg-gray-100 lg:mt-[100px]">
      <nav className="flex items-center space-x-1 text-gray-600 text-[12px]">
        <Link to="/" className="hover:text-blue-500 font-medium flex items-center">
          <FaHome className="mr-1" />
          Home
        </Link>
        {categoryName && (
          <>
            <FaAngleRight />
            <Link to={`/category/${categoryName}`} className="text-blue-600 font-bold">
              {formatText(categoryName)}
            </Link>
          </>
        )}
        {subcategoryName && (
          <>
            <FaAngleRight />
            <Link to={`/subcategory/${subcategoryName}`} className="text-blue-600 font-bold">
              {formatText(subcategoryName)}
            </Link>
          </>
        )}
        {brandName && (
          <>
            <FaAngleRight />
            <span className="font-bold text-blue-600">{formatText(brandName)}</span>
          </>
        )}
      </nav>
    </div>
      {/* Banner Image Section */}
      <div className="w-full relative">
        {
          bannerImage && (
            <img
              className="w-full h-[250px] lg:h-full  object-fit md:object-contain object-center"
              src={
                bannerImage ||
                "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739965883925_1739965883921.webp"
              }
              alt="Banner"
            />)
        }
        {
          !bannerImage && (
            <img
              className="w-full h-[250px] lg:h-[180px]  object-cover object-cover object-center"
              src={
                "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739965883925_1739965883921.webp"
              }
              alt="Banner"
            />
          )
        }


        {/* Category Title (Only if no banner image is available) */}
        {!bannerImage && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/40 text-white">
            <h1 className="text-3xl font-bold md:text-2xl">
              {formatText(subcategoryName) || formatText(categoryName) || formatText(brandName)}
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default UpperBanner;