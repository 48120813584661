import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { asyncFetchFeatureImages } from '../../actions/productAction';

interface Product {
  globalProductId: string;
}

interface Media {
  _id: string;
  url: string;
  publicId?: string;
}

const Features = ({ product }: { product: Product }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { featureImages } = useSelector((state: RootState) => state.product);
  const [carousel1Index, setCarousel1Index] = useState(0); // For indices 0-2
  const [carousel2Index, setCarousel2Index] = useState(0); // For indices 3-4
  const [carousel4Index, setCarousel4Index] = useState(0); // For indices 8-10

  useEffect(() => {
    if (product?.globalProductId) {
      dispatch(asyncFetchFeatureImages(product.globalProductId));
    }
  }, [dispatch, product?.globalProductId]);

  // Auto-carousel effect
  useEffect(() => {
    const totalCarousel2Items = Array.isArray(featureImages) && featureImages.length > 4 ? 2 : 1;

    const interval1 = setInterval(() => {
      setCarousel1Index((prev) => (prev + 1) % 3); // 3 items (0-2)
    }, 3000);
    
    const interval2 = setInterval(() => {
      setCarousel2Index((prev) => (prev + 1) % totalCarousel2Items); // 1 or 2 items (3-4)
    }, 3000);
    
    const interval4 = setInterval(() => {
      setCarousel4Index((prev) => (prev + 1) % 3); // 3 items (8-10)
    }, 3000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval4);
    };
  }, [featureImages]);

  const getMediaElement = (index: number, width: string, height: string) => {
    const media:any = featureImages[index];
    if (!media) return null;

    const isVideo = media.publicId?.endsWith('.mp4') || media.url?.endsWith('.mp4');

    return isVideo ? (
      <video
        src={media.url}
        autoPlay
        muted
        loop
        controls
        className="object-cover"
        style={{ width, height, maxWidth: '100%' }}
        key={media._id}
      />
    ) : (
      <img
        src={media.url}
        alt={`Feature ${index + 1}`}
        className="object-cover object-center"
        style={{ width, height, maxWidth: '100%' }}
        key={media._id}
      />
    );
  };

  // Manual navigation handlers
  const setSlide1 = (index: number) => setCarousel1Index(index);
  const setSlide2 = (index: number) => setCarousel2Index(index);
  const setSlide4 = (index: number) => setCarousel4Index(index);

  const totalCarousel2Items = Array.isArray(featureImages) && featureImages.length > 4 ? 2 : 1;

  return (
    <>
      {Array.isArray(featureImages) && featureImages.length >= 11 && (
        <div className="p-8 w-full bg-gray-50">
          <p className="text-4xl font-bold text-gray-800 mb-8 text-center">FEATURES</p>

          {/* First Carousel - Indices: 0-2 */}
          <div className="relative w-full max-w-[970px] mx-auto mb-8">
            <div className="rounded-xl shadow-2xl overflow-hidden transition-all duration-500">
              {getMediaElement(carousel1Index, '970px', '300px')}
            </div>
            <div className="flex justify-center gap-2 mt-4">
              {[0, 1, 2].map((index) => (
                <button
                  key={index}
                  onClick={() => setSlide1(index)}
                  className={`w-3 h-3 rounded-full ${
                    carousel1Index === index ? 'bg-gray-800' : 'bg-gray-400'
                  }`}
                />
              ))}
            </div>
          </div>

          {/* Second Carousel - Indices: 3-4 (2 items if available, else 1) */}
          <div className="relative w-full max-w-[970px] mx-auto mb-8">
            <div className="rounded-xl shadow-2xl overflow-hidden transition-all duration-500">
              {getMediaElement(3 + carousel2Index, '970px', '300px')}
            </div>
            {totalCarousel2Items > 1 && (
              <div className="flex justify-center gap-2 mt-4">
                {[0, 1].map((index) => (
                  <button
                    key={index}
                    onClick={() => setSlide2(index)}
                    className={`w-3 h-3 rounded-full ${
                      carousel2Index === index ? 'bg-gray-800' : 'bg-gray-400'
                    }`}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Flex Row - Indices: 5-7 (shifted due to carousel2 using 2 items) */}
          <div className="w-[90%] mb-8 flex items-center justify-center mx-auto lg:overflow-scroll">
            <div className="flex gap-6 w-max mx-auto md:overflow-x-scroll">
              {[5, 6, 7].map((index) => (
                <div
                  key={index}
                  className="rounded-xl shadow-2xl transform transition-all duration-500 hover:scale-105 flex-shrink-0"
                >
                  {getMediaElement(index, '300px', '300px')}
                </div>
              ))}
            </div>
          </div>

          {/* Fourth Carousel - Indices: 8-10 */}
          <div className="relative w-full max-w-[970px] mx-auto">
            <div className="rounded-xl shadow-2xl overflow-hidden transition-all duration-500">
              {getMediaElement(8 + carousel4Index, '970px', '300px')}
            </div>
            <div className="flex justify-center gap-2 mt-4">
              {[0, 1, 2].map((index) => (
                <button
                  key={index}
                  onClick={() => setSlide4(index)}
                  className={`w-3 h-3 rounded-full ${
                    carousel4Index === index ? 'bg-gray-800' : 'bg-gray-400'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Features;