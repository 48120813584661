import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FaLocationArrow,
  FaShoppingCart,

} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../common/loader/Loader";
import { BsCart3 } from "react-icons/bs";
import { asyncAddToCart } from "../../../actions/productAction";
import { AppDispatch } from "../../../store/store";
import { asyncUpdateQuantity } from "../../../actions/searchProductAction";
interface LocationDetails {
  deliveryTime: string;
}


interface ProductInfoProps {
  product: any;
  setProduct: any;
  variations: any;
  productsWithSameVariant: any;
  handleSelectVariant: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  product,
  variations,
  handleSelectVariant,
}) => {
  const [quantity, setQuantity] = useState<number>(1);
  const [isAddedToCart,] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("product_id"); // Extract productId from query string
  const [reviewData, setReviewData] = useState({
    avgRating: 0,
  });
  const [loading, setLoading] = useState(false);


  const [isInCart, setIsInCart] = useState(false); // State to track if product is added to cart


  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(e.target.value));
  };


  const calculateDiscountPercentage = (salePrice: any, regularPrice: any) => {
    if (regularPrice <= salePrice) {
      throw new Error("Regular price must be greater than sale price.");
    }
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };

  const handleAddToCartClick = async (product: any) => {
    if (loading) return; // Prevent multiple clicks while adding

    setLoading(true);
    try {
      await handleAddToCart(product); // Wait until the item is actually added
      setIsInCart(true);
    } catch (error) {
      console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false); // Ensure loading stops even if an error occurs
    }
  };
  const handleAddToCart = useCallback(
    async (product: any) => {
      let flag = true
      dispatch(asyncAddToCart(product, flag))
    },
    [dispatch]
  );

  const handleBuyNowClick = async (product: any) => {
    console.log(product)
    await dispatch(asyncAddToCart(product, true))
    await navigate("/checkout"); // This will only execute after products are added

  }


  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cartRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [pincode, setPincode] = useState('');
  const [locationDetails, setLocationDetails] = useState<LocationDetails | null>(null);
  const [error, setError] = useState('');
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(true); // Scroll karte hi cart upar chala jaye

      if (timeoutRef.current) clearTimeout(timeoutRef.current); // Pehle ka timeout clear karo

      timeoutRef.current = setTimeout(() => {
        setIsSticky(false); // 3 sec tak scroll na kare to cart neeche chala jaye
      }, 3000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleUpdateQuantity = async (newQuantity: number) => {
    if (loading || newQuantity > product.totalStock) return;


    setLoading(true);
    try {
      await dispatch(asyncUpdateQuantity(product, newQuantity));
      setQuantity(newQuantity);
    } catch (error) {
      console.error("Failed to update quantity:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pincode === "") {
      setError(""); // Reset error message
      setLocationDetails(null); // Reset location details
    }
  }, [pincode]);


  const handlePincodeCheck = async () => {
    if (pincode.length === 6) {
      try {
        // Fetch the JSON file from the public folder
        const response = await fetch("/pincode.json");
        const deliveryRegions = await response.json();

        let deliveryTime = "Sorry, delivery is not available for this location at the moment."; // Default delivery time

        // Checking the pincode in predefined JSON
        if (deliveryRegions.Gurgaon.includes(pincode)) {
          deliveryTime = "Yay! 🎉 We deliver to your area in just 2 hours. Get ready for super-fast delivery";
        } else if (deliveryRegions["Delhi NCR"].includes(pincode)) {
          deliveryTime = "😃 Your order qualifies for same-day delivery. Sit back and relax!";
        }

        // Set the location details (update structure if required)
        setLocationDetails((prevState: any) => ({
          ...prevState, // Preserve existing data if necessary
          pincode,      // Add pincode
          deliveryTime, // Add delivery time
        }));

        setError("");
      } catch (err) {
        setError("Failed to fetch pincode data");
        setLocationDetails(null);
      }
    } else {
      setError("Please enter a valid 6-digit pincode");
    }
  };


  return (
    <>
      <div className="p-6 sm:p-3 bg-white ">

        {isAddedToCart && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <Loader /> {/* Your loader component */}
          </div>
        )}
        <div className="flex gap-2 ">
          <span className="px-2 py-[5px] rounded-full text-red-900 uppercase border text-[12px] bg-gradient-to-r from-yellow-200 to-yellow-100 font-semibold">
            Limited Time Offer
          </span>
          <span className="rounded-full border px-2 py-[5px] text-[12px] text-red-900">  {calculateDiscountPercentage(
            Number(product?.salePrice),
            Number(product?.regularPrice)
          ) + "% off"}
          </span>

        </div>

        <h1 className="text-2xl  mt-2 mb-2 sm:text-sm">{product?.productName} </h1>
        <div className="flex items-center justify-between">
          <Link
            to="/contact"
            className="block text-red-900 mb-2 text-center flex items-center hover:underline text-xs"
          >
            <FaLocationArrow className="mr-2" />
            Visit Cayro Shop store
          </Link>
        </div>



        <StarRatings rating={reviewData.avgRating} />



        <div className="flex flex-col justify-between items-start relative space-x-4 ">
          {/* Left Side - Product Information */}
          <div className="flex flex-col justify-center mt-2 w-full ">
            {/* Discount, Price, and Regular Price */}
            <div className="mb-4 flex flex-col  ">

              <div className="text-3xl flex font-semibold sm:text-sm flex flex-col">
                <p>₹{product?.salePrice} </p>
                <p className="text-[12px] text-gray-500">
                  (Inclusive of all taxes)
                </p>
              </div>

              <div className="text-[14px] text-gray-500 flex font-semibold sm:text-sm flex gap-2">
                <p className="line-through ">MRP {product?.regularPrice} </p>  <span>(Save Rs {product.regularPrice - product.salePrice})</span>

              </div>


              {/* Stock Availability */}
              <div className="flex items-center gap-8 mt-2 ">
                <p
                  className={
                    product?.totalStock === 0
                      ? "text-blue-800 font-semibold text-sm sm:text-xs uppercase"
                      : "text-[#009c89] font-semibold text-sm sm:text-xs uppercase"
                  }
                >
                  {product?.totalStock === 0 ? "Out of stock" : "In Stock"}
                </p>

              </div>

            </div>
            <div className="flex  gap-2 w-[80%] sm:w-full">
              <div className="text-[13px] p-2 border border-purple-200 bg-gradient-to-r from-purple-50 to-purple-50 rounded-md shadow-sm text-black-400">
                Superfast delivery in Gurgaon – get your order within 2 hours!
              </div>
              <div className="text-[13px] p-2 border border-purple-200 bg-gradient-to-r from-purple-50 to-purple-50 rounded-md shadow-sm text-black-400">
                Same day delivery in Delhi NCR – fast, reliable, and hassle-free!
              </div>
            </div>



          </div>


        </div>
        <div className="flex gap-3 justify-between mt-[20px] md:flex-col">
          <div className=" flex sm:flex flex-col sm:flex-col sm:space-x-0 sm:space-y-4 gap-1 max-w-[450px]">
            {variations?.length > 0 &&
              variations.some((variation: any) => variation?.value?.length > 0) ? (
              variations.map((variation: any) => {
                if (!variation?.name || variation?.name === 'undefined' || !variation?.value || variation?.value.length === 0) {
                  return null; // Skip invalid variations
                }

                return (
                  <div key={variation?.name} className="flex flex-col gap-1 items-start uppercase">
                    <h5 className="text-gray-600 font-bold text-[12px] sm:text-[11px]">
                      {variation?.name}:
                    </h5>
                    <ul className="flex flex-wrap items-center justify-start gap-2 uppercase">
                      {variation.value.map((value: string) => {
                        const isSelected = product?.variation?.some(
                          (varItem: any) =>
                            varItem.name === variation.name && varItem.value === value
                        );

                        return (
                          <li
                            key={value}
                            onClick={() => handleSelectVariant(variation, value)}
                            className={`text-[12px] cursor-pointer px-2 py-1 md:text-[10px] font-normal transition-all duration-300 whitespace-nowrap rounded-md border border-purple-300 shadow-sm ${isSelected
                              ? "text-[12px] bg-gradient-to-r from-purple-200 md:text-[10px] to-purple-200 border-purple-400"
                              : "text-[12px] bg-gradient-to-r from-gray-100 md:text-[10px] to-white hover:from-gray-200 hover:to-gray-100"
                              }`}
                          >
                            {value}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })
            ) : null}

          </div>
          <div className="p-4 bg-gradient-to-b from-pink-100 to-yellow-100 border rounded-lg shadow-md max-w-sm max-h-[160px]">
            <p className="text-lg font-medium text-gray-800">Delivery Details</p>

            <div className="mt-2 flex items-center gap-2 border rounded-lg p-1 border-purple-300 bg-white">
              <span className="text-red-600 text-xl">📍</span>
              <input
                type="text"
                placeholder="Enter Pincode"
                className="w-full outline-none text-gray-700 text-sm p-2 border-none"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
              <button
                onClick={handlePincodeCheck}
                className="px-3 py-1 text-red-900 border border-red-900 rounded-md text-sm font-medium hover:bg-red-100 transition"
              >
                CHECK
              </button>
            </div>

            {error && <p className="text-red-600 text-sm mt-2">{error}</p>}

            {locationDetails && (
              <div className="mt-4">
                <p className="text-gray-700 font-semibold text-[15px]">{locationDetails.deliveryTime}</p>
              </div>
            )}

          </div>

        </div>


        {product?.totalStock !== 0 && (
          <div className="sm:flex-col flex flex-col items-start gap-[10px]">
            <div className="flex flex-col sm:flex">
              {isInCart &&
                product?.quantityByUser > 0 && ( // Show section only if quantity is greater than 0
                  <div className="flex items-center space-x-2 mb-4">
                    <button
                      type="button"
                      onClick={() => handleUpdateQuantity(quantity - 1)}
                      className="p-2 px-6 text-[17px] flex justify-center items-center sm:px-2 bg-gray-200 rounded sm:h-[28px] text-bold"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-16 sm:w-8 p-2 text-[17px] border sm:h-[28px] rounded text-center"
                      value={product?.quantityByUser}
                      onChange={handleQuantityChange}
                      readOnly // Optionally make it read-only to prevent manual input
                    />
                    <button
                      type="button"
                      onClick={() => handleUpdateQuantity(quantity + 1)}
                      className="p-2 sm:p-3 flex justify-center items-center px-6 bg-gray-200 sm:h-[28px] rounded text-bold"
                    >
                      +
                    </button>
                  </div>
                )}

              {isInCart && product?.quantityByUser === product?.totalStock && (
                <h4
                  className="text-xs text-center text-purple-600 mb-[0.6rem]"
                  id="hs-validation-name-error-helper"
                >
                  Only {product?.totalStock} left in stock
                </h4>
              )}
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => handleAddToCartClick(product)}
                className="border sm:min-w-[120px] sm:text-xs border-violet-700 text-violet-500 sm:px-2 sm:py-1 px-4 py-2 rounded-full flex-1 min-w-[184px] hover:bg-white hover:text-purple-500"
              >
                Add to cart
                <BsCart3 className="inline mb-[0.2rem] ml-[0.2rem]" />

              </button>

              <button
                type="submit"
                onClick={() => {
                  handleBuyNowClick(product);

                }}
                className="border sm:min-w-[120px] rounded-full border-purple-700 text-red-500  transition-transform duration-300 transform sm:text-xs text-white bg-violet-800 px-4 py-2 rounded flex-1 min-w-[184px] hover:scale-105"
              >
                Buy Now
              </button>
            </div>
          </div>
        )}
      </div>

      <div
        ref={cartRef}
        className={`w-[90%] border  shadow-indigo-300/50 bg-white p-2 md:p-3 border-t border-violet-400  fixed z-[99999] bottom-6 left-1/2 transform -translate-x-1/2 rounded-lg shadow-xl  transition-transform duration-300 ${isSticky ? "translate-y-0" : "translate-y-full"
          }`}
      >
        <div className="flex  flex-wrap items-center justify-between max-w-4xl mx-auto gap-6 sm:gap-2">
          {/* Product Details */}
          <div className="flex items-center gap-4 flex-1 min-w-[160px]">
            <img
              src={product?.images?.[0]?.url}
              alt={product.productName}
              className="w-[80px] h-[80px] md:h-[60px] md:w-[60px] object-contain rounded-lg shadow-sm"
            />
            <div className="flex-1 min-w-0"> {/* Added min-w-0 to allow shrinking */}
              <p className="text-base font-semibold text-gray-900  truncate md:max-w-[30ch]">
                {product.productName}
              </p>
            </div>

          </div>

          {isInCart && (
            <div className="flex items-center gap-3">
              {/* Decrement Button */}
              <button
                className="border border-gray-300 text-gray-700 px-3 py-1 text-lg md:px-2 md:py-0 md:text-[11px] rounded-full hover:bg-gray-100 transition shadow-sm disabled:opacity-50"
                onClick={() => handleUpdateQuantity(quantity - 1)}
                disabled={quantity <= 1}
              >
                –
              </button>

              {/* Quantity Display */}
              <span className="text-lg md:text-[11px] font-semibold text-gray-800">{quantity}</span>

              {/* Increment Button */}
              <button
                className="border border-gray-300 text-gray-700 px-3 py-1 text-lg md:px-2 md:py-0 md:text-[11px] rounded-full hover:bg-gray-100 transition shadow-sm disabled:opacity-50"
                onClick={() => handleUpdateQuantity(quantity + 1)}
                disabled={quantity >= product.totalStock}
              >
                +
              </button>
            </div>
          )}


          {/* Price Details */}
          <div className="text-right">
            <p className="text-gray-400 text-sm line-through">MRP: ₹{product.regularPrice}</p>
            <p className="text-xl md:text-[16px] font-bold text-gray-900">₹{product.salePrice}</p>
            <p className="text-green-600 text-sm font-medium">
              {((1 - product.salePrice / product.regularPrice) * 100).toFixed(2)}% off
            </p>
          </div>

          {product.totalStock === 0 ? (
            <button
              disabled
              className="flex items-center gap-2 px-6 py-2 md:px-4 rounded-full shadow-md bg-gray-100 text-gray-600 cursor-not-allowed"
            >
              Out of Stock
            </button>
          ) : (
            <button
              onClick={() => handleAddToCartClick(product)}
              disabled={loading}
              className={`flex items-center gap-2 px-6 py-2 md:px-4  rounded-full shadow-md transition-all ${loading
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : isInCart
                  ? "bg-green-600 text-white hover:bg-green-700 md:text-[13px]"
                  : "bg-red-600 text-white hover:bg-red-700 md:text-[13px] "
                }`}
            >
              {loading ? (
                <div className="flex items-center justify-center gap-1">
                  <span className="h-2 w-2 bg-gray-500 rounded-full animate-pulse"></span>
                  <span className="h-2 w-2 bg-gray-500 rounded-full animate-pulse [animation-delay:200ms]"></span>
                  <span className="h-2 w-2 bg-gray-500 rounded-full animate-pulse [animation-delay:400ms]"></span>
                  <span className="ml-2">Updating...</span>
                </div>
              ) : isInCart ? (
                <span>Added to Cart</span>
              ) : (
                <>
                  <span>Add to Cart</span>
                  <FaShoppingCart />
                </>
              )}
            </button>
          )}

        </div>
      </div>
      <div ref={bottomRef} className=" w-full"></div>
    </>
  );
};

const StarRatings = ({ rating }: { rating: number }) => {
  const fullStars = Math.floor(5); // Number of full stars
  const halfStar = rating % 1 >= 0.5; // Whether to show a half star
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars

  return (
    <div className="flex gap-[5px]">
      {Array.from({ length: fullStars }).map((_, index) => (
        <span key={`full-${index}`} className="text-yellow-500">
          ★
        </span>
      ))}

      {halfStar && <span className="text-yellow-500">☆</span>}

      {Array.from({ length: emptyStars }).map((_, index) => (
        <span key={`empty-${index}`} className="text-gray-300">
          ★
        </span>
      ))}
    </div>
  );
};

export default ProductInfo;
