import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { asyncGetCombos } from "../../actions/cayroStoreAction";
import { useNavigate } from "react-router-dom";

const MarriageCombo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { combos ,metaInformation} = useSelector((state: RootState) => state.cayroStore);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(asyncGetCombos());
  }, [dispatch]);

  // Predefined icons, colors, and gradients mapping
  const comboAttributes = {
    "Shagun Combo": {
      icon: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740223648240_1740223648237.webp",
      color: "text-green-600",
      gradient: "from-[#FEAC17] opacity-70",
    },
    "Vivah Combo": {
      icon: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740223621359_1740223621356.webp",
      color: "text-yellow-600",
      gradient: "from-[#C70000] opacity-70",
    },
    "Maharaja Combo": {
      icon: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740223577265_1740223577261.webp",
      color: "text-green-600",
      gradient: "from-[#6ACFBF] opacity-70",
    },
    "Rajwada Combo": {
      icon: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740223599354_1740223599351.webp",
      color: "text-green-600",
      gradient: "from-[#EDD498] opacity-70",
    },
  };

  const handleClick = async (comboName: string, id: string) => {
    const formattedComboName = comboName.replace(/\s+/g, "-"); // Replace spaces with dashes
    await navigate(`/combo?comboName=${formattedComboName}&id=${id}`);
  };

  return (
    <div className="w-full mx-auto p-4 xs:p-2">
      <h1 className="text-2xl font-bold mb-6 xs:text-xl xs:mb-4">
        Marriage Combo
      </h1>

      {/* Main container - now always horizontal */}
      <div className="flex flex-row gap-4 items-start justify-around lg:justify-start overflow-x-auto pb-4 scrollbar-hide relative min-h-[180px] sm:min-h-[200px]">
        {combos.map((combo) => {
          const { comboName, _id }:any = combo;
          const attributes =
            comboAttributes[comboName as keyof typeof comboAttributes] || {};

          return (
            <div
              key={_id}
              className="group flex-none w-[280px] xs:w-[260px] sm:w-[280px] lg:w-[300px] transform transition-all duration-300 hover:scale-105 cursor-pointer"
              onClick={() => handleClick(comboName, _id)}
            >
              <div
                className={`relative flex items-center justify-center w-full h-[120px] xs:h-[130px] sm:h-[140px] bg-gradient-to-b ${attributes.gradient} to-white shadow-md rounded-lg`}
              >
                {/* Content card */}
                <div className="absolute top-0 w-[95%] xs:h-[120px] bg-white shadow-lg mt-6 sm:mt-8 mx-auto p-4 sm:p-6 flex flex-col items-center justify-center rounded-lg transform transition-all duration-300 group-hover:scale-105">
                  {/* Icon container */}
                  <div className="mb-2 sm:mb-4 transform transition-all duration-300">
                    <img
                      src={attributes.icon}
                      alt={comboName}
                      className="w-[80px] h-[80px]"
                    />
                  </div>

                  {/* Title */}
                  <h3 className="text-center text-md text-rose-600 sm:text-base mb-1 sm:mb-2 font-semibold transform transition-all duration-300 group-hover:scale-110">
                    {comboName}
                  </h3>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MarriageCombo;
