import { useMediaQuery } from "@react-hook/media-query";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FaFacebook, FaInstagram, FaPinterest, FaSnapchat, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { IoShareSocialOutline } from "react-icons/io5";
import ConnectToStore from "./ConnectToStore";
import { FaXTwitter } from "react-icons/fa6";


const ProductImage = React.memo((props: any) => {
  let { product } = props;
  const shareBoxRef = useRef<HTMLDivElement>(null);
  const [connectStore, setConnectStore] = useState<boolean>(false);
  const [mainImage, setMainImage] = useState<string | undefined>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const lensRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const zoomedImgRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const [isZoomed, setIsZoomed] = useState(false);
  const productUrl = window.location.href;
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showShareBox, setShowShareBox] = useState(false);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 640); // Tailwind's `sm` breakpoint is 640px
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const handleDoubleClick = () => {
    setIsZoomed(true);
  };

  const closeZoom = () => {
    setIsZoomed(false);
  };

  useEffect(() => {
    if (product?.images?.length > 0) {
      setMainImage(product.images[0].url);
      setCurrentIndex(0); // Initialize currentIndex
    }
  }, [product]);

  useEffect(() => {
    if (product?.images?.length > 0) {
      setMainImage(product.images[currentIndex].url);
    }
  }, [currentIndex, product]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (shareBoxRef.current && !shareBoxRef.current.contains(event.target as Node)) {
        setShowShareBox(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowShareBox]);


  const handleSwipe = (direction: string) => {
    if (product?.images?.length > 0) {
      if (direction === "left") {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
        );
      } else {
        setCurrentIndex((prevIndex) =>
          prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
        );
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const lens = lensRef.current;
    const img = imgRef.current;
    const zoomedImg = zoomedImgRef.current;

    if (lens && img && zoomedImg) {
      const { width, height, left, top } = img.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;

      let lensX = x - lens.offsetWidth / 2;
      let lensY = y - lens.offsetHeight / 2;

      lensX = Math.max(0, Math.min(lensX, width - lens.offsetWidth));
      lensY = Math.max(0, Math.min(lensY, height - lens.offsetHeight));

      lens.style.display = "block";
      zoomedImg.style.display = "block";

      lens.style.left = `${lensX}px`;
      lens.style.top = `${lensY}px`;

      const backgroundX = (x / width) * 100;
      const backgroundY = (y / height) * 100;

      zoomedImg.style.backgroundPosition = `${backgroundX}% ${backgroundY}%`;
    }
  };

  const handleMouseOut = () => {
    const lens = lensRef.current;
    const zoomedImg = zoomedImgRef.current;

    if (lens) lens.style.display = "none";
    if (zoomedImg) zoomedImg.style.display = "none";
  };

  const handleCloseZoomedImage = () => {
    const zoomedImg = zoomedImgRef.current;
    if (zoomedImg) zoomedImg.style.display = "none";
  };


  const handleBackButtonClick = () => {
    navigate(-1); // Navigate to the previous page
  };
  const shareText = "Check out this amazing product!";

  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText + ' ' + productUrl)}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText + ' ' + productUrl)}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productUrl)}`;
  const pinterestUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(productUrl)}`;
  const snapchatUrl = `https://www.snapchat.com/scan?attachment_url=${encodeURIComponent(productUrl)}`;

  const shareLink = async () => {
    const linkToShare = window.location.href; // Or any other URL you want to share
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Check this out!',
          text: 'Here is an interesting link for you.',
          url: linkToShare,
        });
        console.log('Link shared successfully');
      } else {
        alert('Sharing not supported on this device/browser.');
      }
    } catch (err) {
      console.error('Error sharing the link:', err);
    }
  };

  const handleConnectToStore = () => {
    setConnectStore(!connectStore);
  };

  const copyLink = () => {
    const linkToCopy = window.location.href; // Or any other URL you want to copy
    navigator.clipboard.writeText(linkToCopy).then(() => {
      alert('Link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };


  return (
    <div className="product-imgs basis-[35%] sm:basis-[100%] mt-[2rem] sm:mt-[1.8rem] relative">
      <Helmet>
        <meta property="og:image" content={mainImage} />
      </Helmet>

      <button
        onClick={handleBackButtonClick}
        className="text-blue-400 flex text-lg items-center"
      >
        <IoMdArrowRoundBack /> <span className="text-sm">Back</span>

      </button>
      <div className="flex items-center" ref={shareBoxRef}>
        {/* Share Button using React Icon */}
        {showShareBox && (
          <div className="absolute top-7 right-[0%] z-50 bg-gray-900 text-white rounded-full shadow-lg p-3 flex flex-col items-center space-y-3 animate-slide-in">
            {/* Social Media Buttons */}
            <a
              href={whatsappUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-green-600 hover:bg-green-700 transition-colors duration-200 animate-bounce-in"
              title="Share on WhatsApp"
              style={{ animationDelay: "0.1s" }}
            >
              <FaWhatsapp className="text-lg" />
            </a>
            <a
              href={twitterUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-black-500  transition-colors duration-200 animate-bounce-in"
              title="Share on Twitter"
              style={{ animationDelay: "0.2s" }}
            >
              <FaXTwitter className="text-lg" />
            </a>
            <a
              href={facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-blue-800 hover:bg-blue-900 transition-colors duration-200 animate-bounce-in"
              title="Share on Facebook"
              style={{ animationDelay: "0.3s" }}
            >
              <FaFacebook className="text-lg" />
            </a>
            <a
              href={pinterestUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-red-600 hover:bg-red-700 transition-colors duration-200 animate-bounce-in"
              title="Share on Pinterest"
              style={{ animationDelay: "0.5s" }}
            >
              <FaPinterest className="text-lg" />
            </a>
            <a
              href={snapchatUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-yellow-400 hover:bg-yellow-500 transition-colors duration-200 animate-bounce-in"
              title="Share on Snapchat"
              style={{ animationDelay: "0.6s" }}
            >
              <FaSnapchat className="text-lg" />
            </a>



            {/* Copy Link Button */}
            <button
              onClick={copyLink}
              className="p-2 rounded-full bg-gray-700 hover:bg-gray-800 hover:scale-110 transition-all duration-200"
              title="Copy Link"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
              </svg>
            </button>

            {/* Close Button */}
            <button
              onClick={() => setShowShareBox(false)}
              className="hidden p-2 rounded-full hover:bg-gray-700 hover:rotate-90 transition-all duration-300"
              title="Close"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>




          </div>
        )}

        <style>{`
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .animate-slide-in {
    animation: slideIn 0.3s ease-out forwards;
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .animate-bounce-in {
    animation: bounceIn 0.4s ease-out forwards;
  }
`}</style>



      </div>
      <div className="flex flex-row-reverse sm:block">

        <div className="overflow-hidden flex justify-center items-center">
          <div
            className="img-showcase relative flex transition-all duration-500 flex-col"
            onMouseMove={!isMobile ? handleMouseMove : () => { }}
            onMouseOut={!isMobile ? handleMouseOut : () => { }}
            onTouchStart={(e) => {
              const touchStartX = e.touches[0].clientX;
              const handleTouchEnd = (e: any) => {
                const touchEndX = e.changedTouches[0].clientX;
                if (touchStartX > touchEndX + 50) {
                  handleSwipe("right");
                } else if (touchStartX < touchEndX - 50) {
                  handleSwipe("left");
                }
                document.removeEventListener("touchend", handleTouchEnd);
              };
              document.addEventListener("touchend", handleTouchEnd);
            }}
          >
            <p ref={imgRef}>
              {" "}
              <LazyLoadImage
                effect="blur"
                src={mainImage}
                onDoubleClick={isMobile ? handleDoubleClick : () => { }}
                alt={product?.productName}
                className="h-[350px] mb-[1rem] w-[480px] sm:h-[170px] sm:w-[100%] object-contain sm:mb-[0.5rem] duration-300"
              />

            </p>
            {/* connect to store btn
            <div className="flex gap-2 items-center justify-end">
            <MdOutlineLocalGroceryStore className="opacity-80"/>

            <button className="font-medium opacity-80"> Connect To Store</button>
            </div> */}

            {isZoomed && (
              <div className="fixed z-[999999] inset-0  bg-[#ffffffe6]  flex justify-center items-center">
                <div className="relative p-4">
                  <LazyLoadImage
                    effect="blur"
                    src={mainImage}
                    alt={product?.productName}
                    onTouchStart={(e) => {
                      const touchStartX = e.touches[0].clientX;
                      const handleTouchEnd = (e: any) => {
                        const touchEndX = e.changedTouches[0].clientX;
                        if (touchStartX > touchEndX + 50) {
                          handleSwipe("right");
                        } else if (touchStartX < touchEndX - 50) {
                          handleSwipe("left");
                        }
                        document.removeEventListener(
                          "touchend",
                          handleTouchEnd
                        );
                      };
                      document.addEventListener("touchend", handleTouchEnd);
                    }}
                    className="max-w-full max-h-full"
                  />
                  <button
                    onClick={closeZoom}
                    className="absolute top-2 right-2 text-red text-2xl  z-[9999]"
                  >
                    <RxCrossCircled className="text-red-500 font-700 text-[19px] z-[9999]" />
                  </button>

                </div>

              </div>

            )}
            <div
              ref={lensRef}
              className="absolute w-[50px] h-[50px] bg-black bg-opacity-40 border-2 border-gray-500 rounded-full pointer-events-none"
              style={{ display: "none" }}
            ></div>
          </div>
        </div>

        {/* Zoomed Image Display */}

        <div
          ref={zoomedImgRef}
          className="absolute top-0 left-[110%] z-[9999] w-[750px] h-[550px] bg-cover bg-no-repeat border border-gray-300"
          style={{
            backgroundImage: `url(${mainImage})`,
            backgroundSize: "200%",
            display: "none", // Initially hidden
          }}
        ></div>

        <div className="h-[400px] sm:h-[75px] flex flex-col sm:flex-row overflow-x-scroll sm:overflow-y-scroll mt-[20px] relative">


          {product?.images?.length > 0 &&
            product.images.map((image: any, index: number) => {
              return (
                <div
                  key={index}
                  className="m-1 cursor-pointer flex-shrink-0"
                  onMouseEnter={() => setMainImage(image.url)}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={image.url}
                    className="w-[75px] h-[55px] object-contain border p-[5px] border-gray-300 mt-[10px]"
                    alt="product image"
                  />
                </div>
              );
            })}
        </div>
        <button
          className="block md:hidden flex gap-1 absolute right-[0%] top-[0%] z-[44] p-1 cursor-pointer flex justify-center items-center bg-white text-blue-500  rounded-full"
          onClick={() => setShowShareBox(!showShareBox)}
        >
          <IoShareSocialOutline className="text-blue-500 text-[15px]" /> <span>Share</span>
        </button>

        <button
          className="hidden md:flex flex gap-1 absolute right-[0%] top-[-1%] z-[44] p-1 cursor-pointer flex justify-center items-center  text-blue-500 "
          onClick={shareLink}
        >
          <IoShareSocialOutline className="text-blue-500 text-[15px]" /> <span className="text-[15px]">Share</span>
        </button>


      </div>
      <div className="flex md:flex-col items-center p-2 gap-2 shadow-sm bg-white max-w-2xl rounded-lg w-full h-max">
        {/* Product Image */}
        <img
          src={product?.images?.[0]?.url}
          alt="Product"
          className="md:hidden w-20 h-20 object-cover border rounded-lg p-1"
        />

        {/* Bulk Purchase Info */}
        <div className="border rounded-lg flex sm:flex-row gap-2 justify-center flex-1 p-2">
          <div className="flex flex-col gap-2 px-2 ">
            <p className="text-gray-900 font-semibold text-xs">
              LOOKING TO PURCHASE SMART TV IN BULK?
            </p>
            <div className="mt-1 text-gray-600 text-sm space-y-1">
              <p className="flex items-center text-xs ">
                <span className="w-3 h-3 bg-green-400 rounded-full inline-block mr-2"></span>
                Purchase Item In Bulk Quantity
              </p>
              <p className="flex items-center text-xs ">
                <span className="w-3 h-3 bg-green-400 rounded-full inline-block mr-2"></span>
                Get At Best Price For Your Business
              </p>
            </div>
          </div>

          {/* Request Button Section */}
          <div className="border rounded-lg p-2 bg-gradient-to-b from-yellow-100 to-white text-center shadow-md flex flex-col items-center justify-center">
            <p className="text-xs text-gray-700 font-medium">
              We Will Get In Touch With You
            </p>
            <button
              className="mt-1 w-full px-2 py-1 text-red-900 border border-red-900 rounded-md text-sm font-medium hover:bg-red-100 transition"
              onClick={handleConnectToStore} // Show component on click
            >
              REQUEST
            </button>

            {/* Render ConnectToStore component when showConnect is true */}
            {connectStore && (
              <div className="fixed right-2  bottom-10 z-20 ">
                <ConnectToStore />
              </div>
            )}    </div>
        </div>
      </div>
    </div>
  );
});

export default ProductImage;
