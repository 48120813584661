import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store"; // Adjust this import to your store file

interface WishlistItem {
  rating: number;
  addedToWishlist: boolean;
  isLoading: boolean;
  quantityByUser: number; // Changed from `Number` to `number`
  productName: string;
  categoryId: string;
  brandId: string;
  _id: string;
  subCategoryId: string;
  parentCategory: string;
  slug: string;
  regularPrice: string;
  salePrice: string;
  description: string;
  url: string;
  images: {
    publicId: string;
    url: string;
  }[];
  status: number;
  metaInfo: {
    metaInfoTitle: string;
    metaInfoDescription: string;
    metaInfoKeyword: string[];
    metaInfoKeyFeature: string[];
  };
  totalStock: string;
  weight: string;
  gst: string;
  sku_ID: string;
  dimension?: string;
  specification: {
    heading: string;
    feature: { label: string; value: string }[];
  }[];
  variation: {
    name: string;
    value: string[];
  }[];
  reviews: {
    _id: any;
    userName: string;
    review: string;
  }[];
  latest: boolean;
  trending: boolean;
  newLaunches: boolean;
}

export interface WishlistState {
  wishlist: WishlistItem[];
}

const initialState: WishlistState = {
  wishlist: [],
};

export const loadWishlist = createAsyncThunk<WishlistItem[]>(
  "wishlist/loadWishlist",
  async () => {
    const storedWishlist = localStorage.getItem("wishlist");

    const wishlist: WishlistItem[] = storedWishlist
      ? JSON.parse(storedWishlist).wishlist
      : [];

    return wishlist;
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action: PayloadAction<WishlistItem>) => {
      const itemExists = state.wishlist.some(
        (item) => item._id === action.payload._id
      );
      if (!itemExists) {
        state.wishlist.push(action.payload);
        localStorage.setItem("wishlist", JSON.stringify(state));
       
      }
    },
    removeFromWishlist: (state, action: PayloadAction<string>) => {
      state.wishlist = state.wishlist.filter(
        (item) => item._id !== action.payload
      );
      localStorage.setItem("wishlist", JSON.stringify(state));
    },
    clearWishlist: (state) => {
      state.wishlist = [];
      localStorage.removeItem("wishlist");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWishlist.fulfilled, (state, action) => {
      state.wishlist = action.payload;
    });
  },
});

export const { addToWishlist, removeFromWishlist, clearWishlist } =
  wishlistSlice.actions;

export const wishListProducts = (state: RootState) => state?.wishList.wishlist;

export const wishlist = wishlistSlice.reducer;
