import { BsShop } from "react-icons/bs";
import { CgSupport } from "react-icons/cg";
import { FaShippingFast } from "react-icons/fa";
import { MdAssuredWorkload, MdLocalShipping } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
const Services = () => {
    return (
      <div className="w-full py-10 relative flex flex-col items-center overflow-hidden">
        <h1 className="text-3xl font-semibold text-center mb-12 relative z-10 tracking-wider">Our Services</h1>
  
        {/* Circular Blurred Background Bubbles */}
        <div className="absolute top-10 left-[20%] w-48 h-48 bg-red-300/30 backdrop-blur-xl filter blur-3xl rounded-full shadow-[0_0_15px_5px_rgba(0,0,0,0.1)] "></div>
        <div className="absolute bottom-0 right-[0%] w-48 h-48 bg-red-200 backdrop-blur-xl filter blur-3xl rounded-full shadow-[0_0_15px_5px_rgba(0,0,0,0.1)]"></div>
        <div className="absolute bottom-5 left-20 w-48 h-48 bg-red-300/30 backdrop-blur-xl filter blur-3xl rounded-full shadow-[0_0_15px_5px_rgba(0,0,0,0.1)]"></div>
        <div className="absolute top-10 right-[35%] w-48 h-48 bg-red-300/30 backdrop-blur-xl filter blur-3xl rounded-full shadow-[0_0_15px_5px_rgba(0,0,0,0.1)]"></div>
  
        {/* Service Grid */}
        <div className="grid grid-cols-3 gap-[50px] md:grid-cols-2 sm:grid-cols-1 relative z-10">
          {/* Free Shipping */}
          <div className="flex flex-col items-center bg-transparent border border-black p-6 border border-gray-300 transition-all w-[400px] lg:w-auto">
            <FaShippingFast className="text-4xl text-blue-600 mb-3" />
            <span className="text-md text-gray-600 font-semibold">Free Shipping</span>
          </div>
  
          {/* 24X7 Support */}
          <div className="flex flex-col items-center bg-transparent border border-black p-6 border border-gray-300 transition-all w-[400px] lg:w-auto">
            <CgSupport className="text-4xl text-green-600 mb-3" />
            <span className="text-md text-gray-600 font-semibold">24X7 Support</span>
          </div>
  
          {/* Secure Payment */}
          <div className="flex flex-col items-center bg-transparent border border-black p-6 border border-gray-300 transition-all w-[400px] lg:w-auto">
            <RiSecurePaymentFill className="text-4xl text-yellow-600 mb-3" />
            <span className="text-md text-gray-600 font-semibold">Secure Payment</span>
          </div>
  
          {/* One Hour Delivery */}
          <div className="flex flex-col items-center bg-transparent border border-black p-6 border border-gray-300 transition-all w-[400px] lg:w-auto">
            <MdLocalShipping className="text-4xl text-red-600 mb-3" />
            <span className="text-md text-gray-600 font-semibold">One Hour Delivery</span>
          </div>
  
          {/* Best Prices Guarantee */}
          <div className="flex flex-col items-center bg-transparent border border-black p-6 border border-gray-300 transition-all w-[400px] lg:w-auto">
            <MdAssuredWorkload className="text-4xl text-purple-600 mb-3" />
            <span className="text-md text-gray-600 font-semibold">Best Prices Guarantee</span>
          </div>
  
          {/* Visit Our Showroom */}
          <div className="flex flex-col items-center bg-transparent border border-black p-6 border border-gray-300 transition-all w-[400px] lg:w-auto">
            <BsShop className="text-4xl text-pink-600 mb-3" />
            <span className="text-md text-gray-600 font-semibold">Visit Our Showroom</span>
          </div>
        </div>
      </div>
    );
  };
  
  export default Services;
  