import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import Loader from "../../common/loader/Loader";
import { allProduct } from "../../slice/CartSlice";
import { useEffect, useMemo } from "react";
import { getAllProductInCart } from "../../actions/cartAction";

const Subtotal = (props: any) => {
  const {
    handleClickOnPayNowWhenUserIsLoggedIn,
    handleClickOnPayNowWhenUserIsNotLoggedIn,
    isLoadingWhilePayment,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const userIsLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const allProducts = useSelector(allProduct);
  const { subtotal, shippingCharges, total, totalTax } = useSelector(
    (state: RootState) => state.cart
  );

  const { totalAmount, discountAmount, percentageOff } = useMemo(() => {
    let newTotalAmount = 0;
    
    allProducts.forEach((product: any) => {
      newTotalAmount += product?.salePrice * (product?.quantityByUser || 0);
    });
    const newDiscountAmount = newTotalAmount - subtotal;
    const newPercentageOff = (newDiscountAmount / newTotalAmount) * 100;

    return {
      totalAmount: newTotalAmount,
      discountAmount: newDiscountAmount,
      percentageOff: newPercentageOff,
    };
  }, [allProducts, subtotal]);

  useEffect(() => {
    dispatch(getAllProductInCart());
  }, [dispatch]);

  return (
    <div className="border mt-6 w-full basis-[30%] sm:basis-[96%] space-y-6 lg:mt-0 lg:max-w-xs xl:max-w-md bg-white  rounded-lg p-4">
      {/* Product List */}
      <div className="flow-root">
        <div className="max-h-64 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          <div className="divide-y divide-gray-200">
            {Array.isArray(allProducts) ? (
              allProducts.map((product: any) => (
                <div className="flex flex-col">
                  <div
                    key={product._id || product.productName}
                    className="flex items-center justify-between py-3 px-2 hover:bg-gray-100 transition duration-300 rounded-md"
                  >
                    {/* Product Image */}
                    <img
                      src={product?.images[0]?.url || "https://via.placeholder.com/40"}
                      alt={product.productName || "Product Image"}
                      className="w-12 h-12 object-fit rounded-full border border-gray-200 mr-3"
                    />

                    {/* Product Details */}
                    <div className="flex-1 min-w-0">
                      <span className="text-[12px] text-gray-800 font-semibold block">
                        {(product.productName || "Unknown Product").length > 40
                          ? `${(product.productName || "Unknown Product").substring(0, 40)}...`
                          : product.productName || "Unknown Product"}
                      </span>
                      <span className="text-xs text-gray-500">
                        Qty: {product.quantityByUser || 0}
                      </span>
                    </div>

                    {/* Price */}
                    <span className="text-[12px] font-semibold text-gray-900 mr-4">
                      ₹{((product.salePrice || 0) * (product.quantityByUser || 0)).toFixed(2)}
                    </span>

                    {/* Actions with Icons */}

                  </div>

                </div>

              ))
            ) : (
              <div className="py-4 text-center text-gray-500 text-sm">Loading products...</div>
            )}
          </div>
        </div>
      </div>

      {/* Summary */}
      <div className="flow-root bg-gray-50 p-4 rounded-md">
        <div className="-my-2 divide-y divide-gray-200">
          <dl className="flex items-center justify-between gap-4 py-2">
            <dt className="text-sm font-medium text-blue-900">Subtotal</dt>
            <dd className="text-sm font-semibold text-gray-900">₹{subtotal.toFixed(2)}</dd>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-2">
            <dt className="text-sm font-medium text-blue-900">Total Tax</dt>
            <dd className="text-sm font-semibold text-gray-900">₹{totalTax.toFixed(2)}</dd>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-2">
            <dt className="text-sm font-medium text-blue-900">Shipping</dt>
            <dd className="text-sm font-semibold text-gray-900">
              <span className="line-through text-gray-500">₹{shippingCharges.toFixed(2)}</span>{" "}
              <span className="text-green-600">₹0</span>
            </dd>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-2">
            <dt className="text-md font-bold text-gray-900">Grand Total</dt>
            <dd className="text-md font-bold text-gray-900">₹{total.toFixed(2)}</dd>
          </dl>
        </div>
      </div>

      {/* Pay Now Button */}
      <p
        onClick={
          userIsLoggedIn
            ? handleClickOnPayNowWhenUserIsLoggedIn
            : handleClickOnPayNowWhenUserIsNotLoggedIn
        }
        className="w-[80%] text-sm cursor-pointer flex items-center justify-center bg-primary text-white py-2 px-2 rounded-lg text-md w-84 m-auto relative"
      >
        Pay Now
        {isLoadingWhilePayment && (
          <div className="absolute top-[-18px] right-[40%] sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center sm:left-[32px] sm:top-[-2px]">
            <Loader />
          </div>
        )}
      </p>
    </div>
  );
};

export default Subtotal;