import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const RedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    const search = location.search;

    // Redirect if the URL ends with .php
    if (path.endsWith(".php")) {
      navigate("/", { replace: true });
      return;
    }

    // Redirect if the path is /product without query parameters
    if (path === "/product" && search === "") {
      navigate("/", { replace: true });
      return;
    }
  }, [location, navigate]);

  return null; // This component doesn't render anything
};




export const RedirectHandlerTwo = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const category = params.get("category");
        const subcategory = params.get("subcategory");
        const brand = params.get("brandName");
        const brandId=params.get("brand-id")
        const subcategoryId=params.get("sub-category-id")

        if (category) {
            navigate(`/category/${encodeURIComponent(category)}`);
        } else if (subcategory) {
            navigate(`/subcategory/${encodeURIComponent(subcategory)}?sub-category-id=${subcategoryId}`);
        } else if (brand) {
            navigate(`/brand/${encodeURIComponent(brand)}?brand-id=${brandId}`);
        }
    }, [location, navigate]);

    return null; // No UI, just redirects
};

