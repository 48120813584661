import { GetRequest, PostRequest } from "../api/Request"
import { clearCouponSavings, setCartLength, setProductInCart } from "../slice/CartSlice";
import { resetProductList, saveFeatureImages, saveFormattedBrands, saveFormattedCategories, saveFormattedSubcategories, saveLatestProduct, saveProductLists, saveRecommendedProduct, saveTrendingProducts, setErrorMessage } from "../slice/ProductSlice";
import { AppDispatch, RootState } from "../store/store";
import { showErrorToast, showInfoToast, showSuccessToast } from "../utils/toast";
import { getAllProductInCart } from "./cartAction";

export const asyncGetAllLatestProducts = () => async (dispatch: any): Promise<any> => {
  try {
    const response = await GetRequest("getAllProducts?latest=true");
    await dispatch(saveLatestProduct(response.data.products.docs))
  } catch (error: any) {
    showErrorToast(error.response.data.message)

  }
}

export const asyncGetAllTrendingProducts = () => async (dispatch: AppDispatch): Promise<any> => {
  try {
    const response = await GetRequest("getAllProducts?trending=true")
    await dispatch(saveTrendingProducts(response.data.products.docs))
  } catch (error: any) {
    showErrorToast(error.response.data.message)
  }
}
export const asyncGetRecommendedProducts = () => async (dispatch: any): Promise<any> => {
  try {
    const response = await GetRequest('/getAllProducts?recommended=true')
    await dispatch(saveRecommendedProduct(response.data.products.docs))
  } catch (error: any) {
    showErrorToast(error.response.data.message)

  }
}

export const asyncAddToCart =
  (product: any, flag: boolean) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        let cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");
        const productIndex = cartProducts.findIndex(
          (item: any) => item._id === product._id
        );

        if (productIndex === -1) {
          // Add new product only if stock is available
          if (product?.totalStock > 0) {
            cartProducts.push({ ...product, quantityByUser: 1 });
          } else {
            showInfoToast("Out of stock");
            return;
          }
        } else {
          // Check stock limit before increasing quantity
          if (cartProducts[productIndex].quantityByUser < product.totalStock) {
            cartProducts[productIndex].quantityByUser += 1;
          } else {
            showInfoToast("Cannot add more, stock limit reached");
            return;
          }
        }

        localStorage.setItem("cart", JSON.stringify(cartProducts));
        dispatch(setCartLength(cartProducts.length));

        if (flag) {
          showSuccessToast("Product added to cart");
        }
        return;
      }

      // **For logged-in users**
      const response = await PostRequest("/addItemToCart", {
        productId: product?._id,
        quantity: product?.quantityByUser || 1,
      });

      dispatch(setCartLength(response.data.count));

      if (flag) {
        showSuccessToast("Product added to cart");
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message === "Invalid User" ||
        error?.response?.data?.message === "Invalid token"
      ) {
        if (flag) {
          showInfoToast("Please Login");
        }
      } else {
        showErrorToast(
          error?.response?.data?.message || "Failed to add item to cart"
        );
      }
    }
  };

export const asyncUpdateQuantity =
  (product: any, index: number, newQuantity: number) =>
    async (dispatch: Function) => {
      const isLoggedIn = !!localStorage.getItem("userToken");
      const productId = product._id;

      try {
        if (!isLoggedIn) {
          const cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");

          const productIndex = cartProducts.findIndex(
            (cartProduct: any) => cartProduct._id === productId
          );

          // Check if new quantity exceeds stock
          if (newQuantity > product.totalStock) {
            showErrorToast("Quantity exceeds available stock.");
            return;
          }

          if (productIndex > -1) {
            if (newQuantity > 0) {
              cartProducts[productIndex].quantityByUser = newQuantity;
            } else {
              cartProducts.splice(productIndex, 1);
            }
          } else if (newQuantity > 0) {
            cartProducts.push({ ...product, quantityByUser: newQuantity });
          }

          localStorage.setItem("cart", JSON.stringify(cartProducts));
          await dispatch(clearCouponSavings());
          await dispatch(
            setProductInCart({
              product: {
                ...product,
                quantityByUser: newQuantity,
                total: newQuantity * product.salePrice,
              },
              index,
            })
          );

          showSuccessToast(
            newQuantity > 0
              ? "Quantity updated successfully!"
              : "Product removed from cart."
          );

          await dispatch(setCartLength(cartProducts.length));
        } else {
          // For logged-in users, proceed with API call
          const response = await PostRequest("/updateItemQuantity", {
            productId,
            quantity: newQuantity,
          });

          if (response?.data) {

            await dispatch(
              setProductInCart({
                product: {
                  ...product,
                  quantityByUser: newQuantity,
                  total: newQuantity * product.salePrice,
                },
                index,
              })
            );
            await dispatch(clearCouponSavings());
            showSuccessToast(
              newQuantity > 0
                ? "Quantity updated successfully!"
                : "Product removed from cart."
            );

            if (response.data.count !== undefined) {
              dispatch(setCartLength(response.data.count));
            }
          }
        }
      } catch (error: any) {
        showErrorToast(error?.response?.data?.message || "An error occurred.");
      }
    };


export const asyncRemoveItem = (product: any, index: number, newQuantity: number) => async (dispatch: any) => {
  try {
    const isLoggedIn = localStorage.getItem("userToken");

    if (!isLoggedIn) {
      // Handle for non-logged-in users (localStorage-based)
      const cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");
      const productIndex = cartProducts.findIndex((item: any) => item._id === product._id);

      if (productIndex !== -1) {
        if (cartProducts[productIndex].quantityByUser > 1) {
          cartProducts[productIndex].quantityByUser -= 1;
        } else {
          cartProducts.splice(productIndex, 1); // Remove product if quantity is 1
        }

        // Update the localStorage cart
        localStorage.setItem("cart", JSON.stringify(cartProducts));
        await dispatch(clearCouponSavings());

        // Show success toast
        showSuccessToast(
          cartProducts[productIndex]
            ? "Updated Successfully"
            : "Product removed from cart"
        );

        // Dispatch Redux action to update cart length
        dispatch(setCartLength(cartProducts.length));

        // Update the cart state in Redux
        dispatch(setProductInCart({
          product: {
            ...product,
            quantityByUser: newQuantity,
            total: newQuantity * product.salePrice,
          },
          index,
        }));

      }
    } else {
      // For logged-in users, make API call to remove item from cart
      const response = await PostRequest("/removeItemFromCart", {
        productId: product?._id,
      });

      if (response.status === 200) {
        // Dispatch the updated cart length
        dispatch(setCartLength(response.data.cart.items.length));

        // Update the Redux state with the new cart items
        await dispatch(clearCouponSavings());

        await dispatch(getAllProductInCart()); // Assuming you have an action to get all cart items
        showSuccessToast(response.data.message);
      }
    }
  } catch (error: any) {
    // Handle errors
    showErrorToast(
      error.response?.data?.message || "Failed to remove item from cart"
    );
  }
};



export const asyncGetProductByFilters = ({
  navigate,
  subCategoryIdForProduct,
  categoryIdForProduct,
  brandId,
  pageForProduct,
  sortBy,
  minPrice,
  maxPrice,
  ...filters
}: any) => async (dispatch: AppDispatch, getState: any): Promise<any> => {
  dispatch(resetProductList())
  let payload: any = {
    brand: filters.brand, // Directly assign brand filter
    variations: {}, // Initialize variations as an empty object
  };

  for (const [key, value] of Object.entries(filters)) {
    if (key.toLowerCase() !== "brand") {
      payload.variations[key] = value; // Add all non-brand filters to variations
    }
  }

  let baseUrl = '';
  if (brandId) {
    baseUrl = `searchproductbyBrand/${brandId}`;
  } else if (subCategoryIdForProduct) {
    baseUrl = `searchproductbySubCategory/${subCategoryIdForProduct}`;
  } else {
    baseUrl = `searchproductbyCategory/${categoryIdForProduct}`;
  }

  // Construct the URL with query parameters
  const queryParams = new URLSearchParams({
    page: String(pageForProduct),
    ...(minPrice && maxPrice ? { minPrice, maxPrice } : {}), // Add minPrice and maxPrice only if they are provided
    ...(sortBy ? { sortBy } : {}),
  });

  // Final URL with query parameters
  const url = `${baseUrl}?${queryParams.toString()}`;

  try {
    const response = await PostRequest(url, payload);
    if (response.status === 200) {
      const cartProducts = (getState() as RootState).cart.products;
      const products =
        response.data.products.docs.length > 0
          ? response.data.products.docs.map((product: any) => {
            const cartProduct =
              cartProducts &&
              cartProducts.find(
                (cartItem: any) => cartItem._id === product._id
              );

            return {
              ...product,
              quantityByUser: cartProduct
                ? cartProduct.quantityByUser
                : 1,

            };
          })
          : [];
      // Dispatch to reducer
      await dispatch(
        saveProductLists({
          products,
          totalPages: response.data.products.totalPages,
          totalItems: response.data.products.totalDocs,
          page: pageForProduct,
          metaInfo: response.data.metaInfo,
          bannerImage:response.data.bannerImage,
          categoryDescription:response.data.description
        })
      );

      return {
        products,
        totalPages: response.data.products.totalPages,
        totalItems: response.data.products.totalDocs,
        page: pageForProduct,
        metaInfo: response.data.metaInfo,
      };
    } else {
      console.log("Failed to fetch products.");
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      await dispatch(setErrorMessage(error?.response?.data?.error))
      navigate('/')

    }
  }
}


export const asyncFetchFilterFormattedBrand = (brandId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await GetRequest(
        `/get-filter-value-by-brand/${brandId}`
      );
      if (response.status === 200) {
        await dispatch(saveFormattedBrands(response.data.filterValuesFormatted));
      } else {
        // Handle the case where the response is not successful (optional)
      }
    } catch (error: any) {
      showErrorToast(error.response?.data?.message || "An error occurred");
    }
  };
};


export const asyncFetchFilterFormattedCat = (categoryId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await GetRequest(`/get-filter-value-by-category/${categoryId}`)
      if (response.status === 200) {
        await dispatch(saveFormattedCategories(response.data.filterValuesFormatted))
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message)
    }
  }
}


export const asyncFetchFilterFormattedSubCat = (subcategoryId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await GetRequest(`/get-filter-value-by-subcategory/${subcategoryId}`)
    if (response.status === 200) {
      await dispatch(saveFormattedSubcategories(response.data.filterValuesFormatted))
    }
  } catch (error: any) {
    showErrorToast(error.response.data.message)
  }
};


export const asyncFetchFeatureImages = (globalProductId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await PostRequest(`/getDescriptionImages`, { globalProductId })
    if (response.status === 200) {
      await dispatch(saveFeatureImages(response.data.data))
    }
  } catch (error: any) {
    showErrorToast(error.response.data.message)
    await dispatch(saveFeatureImages({}))

  }
}