import { CgChevronRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';

interface UpperBannerProps {
    comboName: string | null;
}
interface NavigationProps {
    comboName: string |null;
    dealName?: string |null;
  }

  
  export const Navigation = ({ comboName, dealName }: NavigationProps) => {
    const truncatedDealName = dealName
    ? dealName.split("-").slice(0, 3).join("-")
    : "";
    return (
        <nav className="flex flex-wrap items-center text-gray-600 text-[14px] p-2">
            <Link
                to="/cayrostore"
                className="hover:text-rose-600 transition-colors"
            >
                Marriage Combo
            </Link>
            {comboName && (
                <>
                    <CgChevronRight className="w-4 h-4 flex-shrink-0" />
                    <span className="text-rose-600 font-small break-words">
                        {comboName}
                    </span>
                </>
            )}

            {dealName && (
                <>
                    <CgChevronRight className="w-4 h-4 flex-shrink-0" />
                    <span className="text-rose-600 font-small break-words">
                        {truncatedDealName}
                    </span>
                </>
            )}
        </nav>
    );
};

const UpperBanner = ({ comboName }: UpperBannerProps) => {
    return (
        <>
            <div className="w-full">
                {/* Breadcrumb Navigation */}
                <div className="max-w-7xl ml-1 py-4 mt-20">
                <Navigation comboName={comboName} dealName={''} />
                </div>

                {/* Banner Section */}
                <div className="relative w-full h-[300px] md:h-[250px] overflow-hidden">
                    {/* Banner Image with Overlay */}
                    <div className="absolute inset-0 bg-black/40 z-10" />
                    <img
                        src="/cayrostore/upperbanner.webp"
                        className="w-full h-full object-cover object-center"
                        alt="Category Banner"
                    />

                    {/* Content Overlay */}
                    <div className="absolute inset-0 z-20">
                        <div className="max-w-7xl mx-auto h-full px-4">
                            <div className="flex flex-col justify-center h-full">
                                {/* Main Title */}
                                <h1 className="text-5xl font-bold text-white mb-4 drop-shadow-lg md:text-4xl sm:text-3xl">
                                    {comboName}
                                </h1>

                                {/* Optional Subtitle or Description */}
                                <p className="text-gray-200 text-lg max-w-xl md:text-base sm:text-sm">
                                    Discover our exclusive collection of {comboName} packages
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Decorative Elements */}
                    <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-black/20 to-transparent z-10" />
                </div>

                <p className='text-center text-white bg-[#CC79A0] p-4 mt-[50px] text-3xl lg:text-xl'>Naye Jeevan Ki Shuruaat, Sabse Behtareen Electronic Deals Ke Saath!</p>
            </div>

        </>

    );
};

export default UpperBanner;