const Banner = () => {
    return (
        <>
            <div className="w-full relative mt-[100px]">
                <img
                    className="w-full h-full object-cover md:object-contain object-center"
                    src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1740048826010_1740048826007.webp "
                    alt="Banner"
                />
                </div>
        </>
    );
  };
  
  export default Banner;