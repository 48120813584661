import { axiosRequest } from "../api/Axios";



const axiosPrivate = async (config: any) => {
  try {
    let token: string = localStorage.getItem("userToken") || "";

    // Attach the token to the headers
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `${token}`,
      };
    }

    // Make the actual request
    const response = await axiosRequest(config);
    return response;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., log out the user)
      // dispatch(logOutAsyncThunk(''));
    }
    throw error;
  }
};


export const GetRequest = async (URL: string) => {
  return axiosPrivate({
    method: "get",
    url: URL,
  });
};

export const PostRequest = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "post",
    url: URL,
    data: data,
  });
};

export const PutRequest = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "put",
    url: URL,
    data: data,
  });
};

export const PatchRequest = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "patch",
    url: URL,
    data: data,
  });
};

export const PatchRequestWithFile = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "patch",
    url: URL,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data", // Required for file uploads
    },
  });
};

export const DeleteRequest = async (URL: string) => {
  return axiosPrivate({
    method: "delete",
    url: URL,
  });
};

export const PostRequestForFile = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "post",
    url: URL,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data", // Required for file uploads
    },
  });
};
