import { Helmet } from "react-helmet"
import Banner from "./Banner"
import CarouselWithText from "./BannerCaroeul"
import MarriageCombo from "./ComboCat"
import Discount from "./Discount"
import Gifting from "./Gifting"
import LastBanner from "./LastBanner"
import MagicalMoment from "./MagicalMoment"
import Services from "./Services"
import StoreImage from "./StoreImage"


const Cayrostore = () => {
    return <>
        <Helmet>
            <meta
                name="title"
                content="Exclusive Combo Offers – Shagun, Vivah, Rajwada & Maharaja | CayroShop"
            />

            <meta name="description"
                content="Discover premium combo offers at CayroShop! 🎁 Get Shagun, Vivah, Rajwada & Maharaja combos featuring Smart TVs, Refrigerators, Washing Machines & more. Shop now & save big!"
            />
            <meta name="keywords" content="Shagun Gift , Vivah Gifts Set, Home Decore " />

        </Helmet>
        <Banner />
        <MarriageCombo />
        <CarouselWithText />
        <Gifting />
        <StoreImage />
        <MagicalMoment />
        <Services />
        <Discount />
        {/* <Video/> */}
        <LastBanner />
    </>
}

export default Cayrostore