import React from 'react';

const Sitemap = () => {
  const sitemapLinks = [
    {
      title: 'Clearance Sale',
      loc: 'https://www.cayroshop.com/category/Clearance-Sale',
    },
    {
      title: 'Audio Products',
      loc: 'https://www.cayroshop.com/category/Audio',
    },
    {
      title: 'Kitchen Appliances',
      loc: 'https://www.cayroshop.com/category/Kitchen-Appliances',
    },
    {
      title: 'Mobile Phones',
      loc: 'https://www.cayroshop.com/category/Mobiles',
    },
    {
      title: 'Personal Care Products',
      loc: 'https://www.cayroshop.com/category/Personal-Care',
    },
    {
      title: 'Audio',
      loc: 'https://www.cayroshop.com/category/Audio',
    },
    {
      title: 'Home Appliances',
      loc: 'https://www.cayroshop.com/category/Home-Appliances',
    },
    {
      title: 'Hot Deals',
      loc: 'https://www.cayroshop.com/category/Hot-Deals',
    },
    {
      title: 'Laptops',
      loc: 'https://www.cayroshop.com/category/Laptops',
    },
  ];

  return (
    <div className="min-h-screen w-full bg-gray-100 py-8 mt-[100px]">
      <div className="bg-white rounded-lg shadow-lg p-8 h-full flex items-center justify-center">
        <div className="flex flex-wrap justify-center sm:justify-between text-lg font-medium text-gray-700">
          {sitemapLinks.map((link, index) => (
            <div key={index} className="text-center mx-2 mb-4 sm:mb-0">
              <a
                href={link.loc}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-500 transition-colors duration-200"
              >
                {link.title}
              </a>
              
              {index < sitemapLinks.length - 1 && (
                <span className="mx-2 text-gray-400  lg:inline">|</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
