import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { asyncAddToCart } from "../../../actions/productAction";
import { AppDispatch } from "../../../store/store";
import { showSuccessToast } from "../../../utils/toast";
import { applyCoupon } from "../../../slice/CartSlice";
import { FaSpinner } from "react-icons/fa"; // Import spinner icon

const Products = ({ combodeals }: any) => {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    // State to track added products
    const [addedToCart, setAddedToCart] = useState<string[]>([]);
    const [loading, setLoading] = useState<string | null>(null); // Track loading for each product

    const handleAddToCart = async (products: any, code: any, discount: any, comboId: string) => {
        if (loading === comboId) return; // Prevent duplicate clicks

        setLoading(comboId); // Set loading state

        let flag = false;
        for (const product of products) {
            await dispatch(asyncAddToCart(product, flag));
        }

        const payload = {
            coupon: { code: code, discount: discount },
            products: products,
        };

        await dispatch(applyCoupon(payload));
        showSuccessToast("Products Added to cart");

        // Add comboId to the state to mark it as added & remove loader
        setAddedToCart((prev) => [...prev, comboId]);
        setLoading(null);
    };

    const handleCardClick = async (combo: any) => {
        await navigate(
            `/product-combo?dealname=${encodeURIComponent(combo.dealName).replace(/%20/g, '-')}&comboName=${encodeURIComponent(combo.comboname.comboName).replace(/%20/g, '-')}&dealID=${combo._id}`
        );
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 ">
                {combodeals?.length > 0 ? (

                    <div className="grid grid-cols-3 xss:grid-cols-1 smm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">

                        {combodeals?.map((combo: any) => {
                            const totalRegularPrice = combo?.allproducts?.reduce((sum: number, product: any) => sum + (product?.regularPrice || 0), 0);
                            const totalSalePrice = combo?.allproducts?.reduce((sum: number, product: any) => sum + (product?.salePrice || 0), 0);

                            return (
                                <div key={combo?._id} className="relative rounded-lg overflow-hidden group shadow-md "  >
                                    <div className="relative p-6">
                                        <img
                                            src={combo.images[0]?.location}
                                            alt={combo.dealName}
                                            className="w-full h-64 object-contain rounded-lg transition-transform duration-300 group-hover:scale-105 cursor-pointer" onClick={() => handleCardClick(combo)}
                                            
                                        />

                                        <div className="flex flex-col gap-3 mt-[10px] cursor-pointer" onClick={() => handleCardClick(combo)}>
                                            <div className="flex justify-between">
                                                <h3 className="text-[15px] font-bold text-orange-700 mb-2">
                                                {combo.dealName.length > 70 ? `${combo.dealName.slice(0, 70)}...` : combo.dealName}
                                                </h3>
                                                {/* {combo.discount > 0 && (
                                                    <div className="top-8 right-8 text-red-700 px-3 py-1 font-semibold">
                                                        Discount - ₹{combo.discount}
                                                    </div>
                                                )} */}
                                            </div>

                                            <div className="flex justify-between items-center">
                                                <div className="flex items-start gap-2 mb-4 text-[16px]">
                                                    <span className="text-black">₹{totalSalePrice}</span>
                                                    <span className="text-gray-500 line-through">₹{totalRegularPrice}</span>
                                                </div>

                                                <button
                                                    className={`text-[13px] w-[130px] p-1 text-[10px] flex items-center justify-center gap-2 transition-colors duration-300 rounded-full ${addedToCart.includes(combo._id) || combo.allproducts.some((product: any) => product.totalStock === 0)
                                                            ? "bg-gray-400 text-white cursor-not-allowed" // Disabled styling
                                                            : "bg-white text-red-600 border border-yellow-600"
                                                        }`}
                                                    onClick={() =>
                                                        !addedToCart.includes(combo._id) &&
                                                        !combo.allproducts.some((product: any) => product.totalStock === 0) &&
                                                        handleAddToCart(combo.allproducts, combo.cupons.code, combo.cupons.discount, combo._id)
                                                    }
                                                    disabled={addedToCart.includes(combo._id) || loading === combo._id || combo.allproducts.some((product: any) => product.totalStock === 0)}
                                                >
                                                    {combo.allproducts.some((product: any) => product.totalStock === 0) ? (
                                                        "Out of Stock"
                                                    ) : loading === combo._id ? (
                                                        <>
                                                            <FaSpinner className="animate-spin" /> {/* Loader */}
                                                            Adding...
                                                        </>
                                                    ) : addedToCart.includes(combo._id) ? (
                                                        "Added to Cart"
                                                    ) : (
                                                        "Add To Cart"
                                                    )}
                                                </button>


                                            </div>
                                        </div>
                                    </div>

                                    {!combo.status && (
                                        <div className="absolute top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center">
                                            <span className="bg-red-600 text-white px-4 py-2 rounded-full font-semibold">
                                                Out of Stock
                                            </span>
                                        </div>
                                    )}
                                </div>
                            );
                        })}

                    </div>
                ) : (
                    <p className="text-center text-gray-500 text-xl font-semibold mt-10">
                        No deals found
                    </p>
                )}
            </div>
            <p className="text-center text-white bg-[#CC79A0] p-4 mt-[50px] text-3xl lg:text-xl">
                Jaane se pehle soch lo Aisi dhamakedar electronic deal phir kahaan milegi!
            </p>
        </>
    );
};

export default Products;
