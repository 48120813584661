import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { asyncFetchBlogById, fetchBlogs } from "../../slice/BlogSlice";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsCalendarEvent, BsHeart } from "react-icons/bs";
import { CgLock, CgUser } from "react-icons/cg";

interface ExpandedPosts {
  [key: string]: boolean;
}

export const Blog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { blogs, loading, error } = useSelector((state: RootState) => state.blog);
    const firstBlog = blogs[0] as any;
    const createdAtDate = new Date(firstBlog?.createdAt);

    // Check if the date is valid    

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);



  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse text-blue-500">Loading...</div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>;
  }




  return (
    <div className="min-h-screen bg-gray-50">
      {/* Featured Section */}
      <div className="w-full h-[250px] flex justify-center items-center p-4 mt-[100px] relative">
  <div className="w-[90%] h-full"></div>

  <div className="border bg-sky-600 w-full h-[180px] absolute flex items-center justify-around">
    <img src='https://cayrobucket.blr1.digitaloceanspaces.com/images/1739957674621_1739957674611.svg' alt="" className="w-[9vw] sm:w-[14vw] xs:w-[18vw] md:w-[12vw] lg:w-[10vw]" />
    
    <h1 className="text-3xl sm:text-xl xs:text-lg md:text-sm text-white">
      Aapka Bharosa, Hamari Pehchaan
    </h1>

    <img src='https://cayrobucket.blr1.digitaloceanspaces.com/images/1739957525669_1739957525592.svg' alt="" className="w-[22vw] sm:w-[30vw] xs:w-[35vw] md:w-[25vw] lg:w-[22vw]" />
  </div>
</div>

  
      {/* Blog Cards Section */}
      <div className="flex flex-col bg-gray-50 rounded-lg overflow-hidden w-full">
   
  
        {/* Loop through all blogs */}
        <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="grid grid-cols-3 sm:grid-cols-1 gap-6">
        {blogs.map((blog:any) => {
          const date = new Date(blog.createdAt);
          const formattedDate = date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });

          return (
            <article
              key={blog._id}
              className="bg-white rounded-xl shadow-md overflow-hidden group hover:shadow-xl transition-all duration-300 flex flex-col h-full"
            >
              <div className="relative overflow-hidden aspect-video">
                <img
                  src={blog.images[0]?.url || "/api/placeholder/400/300"}
                  alt={blog.title}
                  className="w-full h-full object-contain transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              
              <div className="p-6 flex flex-col flex-grow">
                <div className="flex items-center text-gray-500 text-sm mb-3">
                  <BsCalendarEvent size={14} className="mr-2" />
                  <time dateTime={blog.createdAt}>{formattedDate}</time>
                </div>
                
                <h2 className="font-bold text-xl text-gray-900 mb-3 line-clamp-2">
                  {blog.title}
                </h2>
                
                <div className="mt-auto">
                  <Link
                    to={`/blog/${blog._id}`}
                    className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors"
                  >
                    Read More
                    <svg
                      className="ml-1 w-4 h-4 group-hover:translate-x-1 transition-transform"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </div>
  
        {/* Footer Section */}
        <div className="w-full h-[300px] bg-gradient-to-r from-sky-500 via-white to-sky-500 p-6 flex items-center justify-center">
          <h1 className="text-black text-3xl md:text-2xl text-center">
            Sahi Daam Par Behtareen Chuno, <br />Delhi Ncr aur Gurugram Mein Bharose<br />
            Ka Naya Naam!
          </h1>
        </div>
      </div>
    </div>
  );
  
};






export const BlogById: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { selectedBlog, loading, error } = useSelector((state: RootState) => state.blog);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(asyncFetchBlogById(id));
    }
  }, [id, dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
          <p className="text-gray-600 font-medium">Loading post...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
          <div className="text-red-500 text-lg mb-4">⚠️</div>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Error Loading Post</h2>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => navigate(-1)}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  if (!selectedBlog) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center text-gray-600">
          <h2 className="text-xl font-semibold mb-2">No Blog Post Found</h2>
          <button
            onClick={() => navigate(-1)}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Return to Blog List
          </button>
        </div>
      </div>
    );
  }

  const formattedDate = new Date(selectedBlog.createdAt).toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric"
  });

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="w-full h-[40vh] relative bg-gray-900">
        <img
          src={selectedBlog.images[0]?.url || "/api/placeholder/1200/800"}
          alt={selectedBlog.title}
          className="w-full h-full object-cover opacity-60"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900/70" />
        
        {/* Navigation */}
        <button
          onClick={() => navigate(-1)}
          className="absolute top-6 left-6 flex items-center space-x-2 text-white bg-gray-900/30 backdrop-blur-sm px-4 py-2 rounded-full hover:bg-gray-900/50 transition duration-200"
        >
          <FaArrowLeft size={16} />
          <span>Back</span>
        </button>
      </div>

      {/* Content Section */}
      <div className="max-w-4xl mx-auto px-4 -mt-32 relative z-10">
        <div className="bg-white rounded-xl shadow-xl overflow-hidden">
          {/* Article Header */}
          <div className="p-6 md:p-8 pb-4">
            <h1 className="text-2xl  font-bold text-gray-900 mb-4">
              {selectedBlog.title}
            </h1>
            
            <div className="flex flex-wrap items-center gap-4 text-sm text-gray-600">
              <div className="flex items-center">
                <BsCalendarEvent size={16} className="mr-2" />
                {formattedDate}
              </div>
              <div className="flex items-center">
                <CgUser size={16} className="mr-2" />
                By Admin
              </div>
              <div className="flex items-center">
                <CgLock size={16} className="mr-2" />
                5 min read
              </div>
            </div>
          </div>

          {/* Article Content */}
          <div className="px-6 md:px-8">
            <div 
              className="prose prose-lg max-w-none prose-headings:text-gray-900 prose-p:text-gray-700 prose-a:text-blue-600"
              dangerouslySetInnerHTML={{ __html: selectedBlog.description }}
            />
          </div>

          {/* Interaction Bar */}
          <div className="border-t mt-8">
            <div className="px-6 md:px-8 py-4 flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <button className="flex items-center space-x-2 text-gray-600 hover:text-red-500 transition-colors">
                  <BsHeart size={20} />
                  <span className="text-sm font-medium">Like</span>
                </button>
             
              </div>
              
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">Was this helpful?</span>
                <button className="text-gray-600 hover:text-blue-500 transition-colors">
                  👍
                </button>
                <button className="text-gray-600 hover:text-blue-500 transition-colors">
                  👎
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

