import  { useState, useEffect } from 'react';

const TopCellPhones = () => {
  const images = [
    {
      src: 'https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205524338_1740205524336.webp',
      link: 'https://www.cayroshop.com/brand/Realme?brand-id=66b7222804044857c3f2aad4&page=1',
    },
    {
      src: 'https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205438663_1740205438661.webp',
      link: 'https://www.cayroshop.com/brand/Xiaomi?brand-id=66b723b304044857c3f2acde&page=1',
    },
    {
      src: 'https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205624730_1740205624728.webp',
      link: 'https://www.cayroshop.com/brand/Samsung?brand-id=66b1b17e36e8634886c53309&page=1',
    },
    {
      src: 'https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205601104_1740205601100.webp',
      link: 'https://www.cayroshop.com/brand/Vivo?brand-id=66b0c9b136e8634886c530ab&page=1',
    },
  ];

  const brands = [
    {
      name: 'Vivo',
      logo: 'https://in-exstatic-vivofs.vivo.com/gdHFRinHEMrj3yPG/1692691698575/c0f180bba865685a87025f8ff514ab13.png',
      link: 'https://www.cayroshop.com/brand/Vivo?brand-id=66b0c9b136e8634886c530ab&page=1',
    },
    {
      name: 'Redmi',
      logo: 'https://cayrobucket.blr1.digitaloceanspaces.com/product/67a9c8af34ade3973c226ca2/1739251541300_1739251541274.webp',
      link: 'https://www.cayroshop.com/brand/Xiaomi?brand-id=66b723b304044857c3f2acde&page=1',
    },
    {
      name: 'Samsung',
      logo: 'https://cayrobucket.blr1.digitaloceanspaces.com/product/66c9b5b7ad0fde84ea1a7931/1729930075373_1729930075352.webp',
      link: 'https://www.cayroshop.com/brand/Samsung?brand-id=66b1b17e36e8634886c53309&page=1',
    },
    {
      name: 'Oppo',
      logo: 'https://cayrobucket.blr1.digitaloceanspaces.com/product/66c9b5b7ad0fde84ea1a793a/1729921363653_1729921363620.webp',
      link: 'https://www.cayroshop.com/brand/Oppo?brand-id=66b7193104044857c3f2a894&page=1',
    },
    {
      name: 'Realme',
      logo: 'https://cayrobucket.blr1.digitaloceanspaces.com/product/66c9b5b7ad0fde84ea1a7946/1729852063652_1729852063627.webp',
      link: 'https://www.cayroshop.com/brand/Realme?brand-id=66b7222804044857c3f2aad4&page=1',
    },
    {
      name: 'Xiaomi',
      logo: 'https://cayrobucket.blr1.digitaloceanspaces.com/product/66c9b5b7ad0fde84ea1a7954/1730808764177_1730808764154.webp',
      link: 'https://www.cayroshop.com/brand/Xiaomi?brand-id=66b723b304044857c3f2acde&page=1',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [images.length]);

  return (
    <div className="flex p-4 w-full flex-col">
      <h3 className=" mb-4 text-[20px] font-semibold text-right">Top Cellphones</h3>
      <div className="flex flex-row-reverse sm:flex-col sm:w-full">
        {/* Carousel Section */}
        <div className="w-full md:w-full flex justify-center items-center relative">
          <a href={images[currentIndex].link} target="_blank" rel="noopener noreferrer">
            <img
              src={images[currentIndex].src}
              alt={`Cellphone ${currentIndex + 1}`}
              className="w-full h-auto object-contain rounded-lg"
            />
          </a>
          {/* Navigation Dots */}
          <div className="absolute bottom-1 flex space-x-2">
            {images.map((_, index) => (
              <span
                key={index}
                className={`cursor-pointer transition-all duration-300 ${
                  currentIndex === index
                    ? 'w-6 h-2 bg-blue-500 rounded-full'
                    : 'w-3 h-2 bg-gray-300 rounded-full'
                }`}
                onClick={() => setCurrentIndex(index)}
              ></span>
            ))}
          </div>
        </div>

        {/* Brands Section */}
        <div className="w-full md:w-full mt-4 md:mt-0 md:ml-4 p-4">
          <div className="grid grid-cols-3 gap-4">
            {brands.map((brand, index) => (
              <a
                key={index}
                href={brand.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center items-center text-center space-y-2 flex-col"
              >
                <img
                  src={brand.logo}
                  alt={brand.name}
                  className="w-16 h-16 object-contain"
                />
                <span className="text-sm font-medium">{brand.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCellPhones;
