const Banner = () => {
  return (
      <>
          <div className="w-full relative">
              <img
                  className="w-full h-full object-cover md:object-contain object-center"
                  src="https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741338670636_1741338670627.webp"
                  alt="Banner"
              />
              </div>
      </>
  );
};

export default Banner;