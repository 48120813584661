import { Helmet } from "react-helmet";

const ShippingDeliveryPolicy = () => {
  return (
    <div className="shipping-delivery-policy-container mt-[7rem] w-[95%] mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1 className="text-2xl sm:text-lg font-bold mb-4">
        Shipping and Delivery Policy
      </h1>

      <h2 className="text-lg font-semibold mt-6">
        1. Shipping Methods and Options
      </h2>
      <p className="mb-4">
        We offer a variety of shipping methods to meet your needs, including
        standard, expedited, and express shipping. The available shipping
        options, along with the estimated delivery times and costs, will be
        displayed at checkout based on your location and the items in your cart.
      </p>

      <h2 className="text-lg font-semibold mt-6">2. Delivery Timeframes</h2>
      <ul className="list-disc ml-5 mb-4">
        <li>
          Standard Shipping: Typically takes <strong>same day</strong> after the
          order has been processed and shipped.
        </li>
        <li>
          Expedited Shipping: Typically takes <strong>same day</strong> after
          the order has been processed and shipped.
        </li>
        <li>
          Express Shipping: Typically takes <strong>same day</strong> after the
          order has been processed and shipped.
        </li>
      </ul>
      <p className="mb-4">
        Please note that delivery times are estimates and may vary due to
        factors outside of our control, such as carrier delays, weather
        conditions, or holidays.
      </p>

      <h2 className="text-lg font-semibold mt-6">3. Order Processing Time</h2>
      <p className="mb-4">
        Orders are typically processed within <strong>1 hour</strong> after
        payment has been confirmed. Orders placed on weekends or holidays will
        be processed on the same day.
      </p>

      <h2 className="text-lg font-semibold mt-6">4. Shipping Costs</h2>
      <p className="mb-4">
        Shipping costs are calculated based on the weight, dimensions, and
        destination of the items purchased. The total shipping cost will be
        displayed at checkout before you complete your order. Occasionally, we
        may offer promotions or discounts on shipping, which will be reflected
        in the final order total.
      </p>

      <h2 className="text-lg font-semibold mt-6">5. Tracking Your Order</h2>
      <p className="mb-4">
        Once your order has been shipped, you will receive a confirmation email
        with a tracking number. You can use this number to track your package's
        status on our website or directly through the carrier's website. If you
        do not receive a tracking number, please contact our customer service
        team at{" "}
        <a href="mailto:info@cayroshop.com" className="text-blue-600 underline">
          info@cayroshop.com
        </a>{" "}
        or 9599383349.
      </p>

      <h2 className="text-lg font-semibold mt-6">6. International Shipping</h2>
      <p className="mb-4">
        We do not deliver international shipments right now.
      </p>

      <h2 className="text-lg font-semibold mt-6">7. Shipping Restrictions</h2>
      <p className="mb-4">
        Due to carrier restrictions, certain items or locations may not be
        eligible for shipping. We currently do not ship to out of NCR addresses,
        and all international destinations. Please check the shipping options at
        checkout to see if your location is eligible for delivery.
      </p>

      <h2 className="text-lg font-semibold mt-6">
        8. Failed Delivery Attempts
      </h2>
      <p className="mb-4">
        If a delivery attempt fails due to an incorrect address provided by the
        customer or if the customer is unavailable to receive the package,
        additional shipping charges may apply for re-delivery. Please ensure
        that the shipping address and contact information provided are accurate
        to avoid delays.
      </p>

      <h2 className="text-lg font-semibold mt-6">9. Damaged or Lost Items</h2>
      <p className="mb-4">
        If your order arrives damaged or if you believe your package has been
        lost, please contact us immediately at{" "}
        <a href="mailto:info@cayroshop.com" className="text-blue-600 underline">
          info@cayroshop.com
        </a>{" "}
        or 9599383349. We will work with the carrier to resolve the issue and
        may provide a replacement or refund as appropriate. Claims for lost or
        damaged items must be reported within <strong>3 days</strong> of the
        delivery date.
      </p>

      <h2 className="text-lg font-semibold mt-6">10. Delivery Delays</h2>
      <p className="mb-4">
        While we strive to deliver your order within the estimated timeframe,
        unforeseen circumstances such as natural disasters, pandemics, or
        carrier delays may affect delivery times. We are not responsible for
        delays caused by such external factors. In case of significant delays,
        we will keep you informed and work with you to find a satisfactory
        solution.
      </p>

      <h2 className="text-lg font-semibold mt-6">11. Order Pickup</h2>
      <p className="mb-4">
        [If applicable] We offer an option for in-store or curbside pickup. You
        will receive a notification when your order is ready for pickup,
        typically within <strong>1 hour</strong> after the order is processed.
        Please bring a copy of your order confirmation and a valid ID to collect
        your order.
      </p>

      <h2 className="text-lg font-semibold mt-6">
        12. Changes to Shipping and Delivery Policy
      </h2>
      <p className="mb-4">
        We reserve the right to modify our shipping and delivery policy at any
        time without prior notice. Any changes will be effective immediately
        upon posting on this website. Your continued use of the website
        following the posting of changes constitutes your acceptance of such
        changes.
      </p>

      <h2 className="text-lg font-semibold mt-6">Contact Information</h2>
      <p className="mb-4">
        If you have any questions or concerns about our shipping and delivery
        policy, please contact us at:
      </p>
      <ul className="list-disc ml-5">
        <li>
          Email:{" "}
          <a
            href="mailto:info@cayroshop.com"
            className="text-blue-600 underline"
          >
            info@cayroshop.com
          </a>
        </li>
        <li>Phone: 9599383349</li>
        <li>
          Address: Dcg, 104, Dlf Corporate Greens, Sector 74A, Gurugram,
          Haryana-122001
        </li>
      </ul>
    </div>
  );
};

export default ShippingDeliveryPolicy;
