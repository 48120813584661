const MagicalMoment = () => {
    return (
      <>
        <div className="flex items-center justify-center bg-[#FEF7E5] w-full mt-[10px] px-6 py-10 md:flex-col md:gap-6">
          
          {/* Left Section - Image */}
          <div className="w-[50%] md:w-full flex justify-center">
            <img 
              src="https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741322773550_1741322773546.webp" 
              className="w-full max-w-[500px] h-auto" 
              alt="Magical Moments"
            />
          </div>
  
          {/* Right Section - Text */}
          <div className="w-[50%] md:w-full flex flex-col items-center text-center">
            <h3 className="text-rose-400 font-semibold text-3xl tracking-wider md:text-2xl">
              Magical Moments
            </h3>
            <p className="text-2xl md:text-lg tracking-widest leading-[1.5] mt-[30px] md:mt-[20px]">
              Agar tumhe bhi apne ghar mein wo <br />
              magic chahiye, jo har cheez ko aasaan <br />
              bana de, toh Cayroshop ke electronics <br />
              zaroor check karo. Maine kiya, aur ab <br />
              life bohot smooth ho gayi hai!
            </p>
          </div>
          
        </div>
      </>
    );
  };
  
  export default MagicalMoment;
  