import { useEffect, useState } from "react";

const UpdateForm = ({ index, handleUpdateAddress, setIndexForUpdate }: any) => {
    const [states, setStates] = useState<string[]>([]);
    const [validPincodes, setValidPincodes] = useState(new Set<string>());
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        // Fetch states from states.json
        fetch("/states.json")
            .then((res) => res.json())
            .then((data) => setStates(data.names))
            .catch((error) => console.error("Error fetching states:", error));

        // Fetch pincodes from pincode.json
        fetch("/pincode.json")
            .then((res) => res.json())
            .then((data) => {
                const pincodes = new Set(Object.values(data).flat().map(String)); // Ensure all pincodes are strings
                setValidPincodes(pincodes);
            })
            .catch((error) => console.error("Error fetching pincodes:", error));
    }, []);

    const [updatedAddress, setUpdatedAddress] = useState({
        name: "",
        mobileNumber: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        landmark: "",
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        if (name === "postalCode") {
            const enteredPincode = value.trim(); // Trim spaces
            if (enteredPincode.length === 6) { // Ensure only 6-digit input is checked
                if (validPincodes.has(enteredPincode)) {
                    setErrorMessage(""); // Valid pincode, no error
                } else {
                    setErrorMessage("Location not serviceable");
                }
            } else {
                setErrorMessage(""); // Reset message if input is incomplete
            }
        }

        setUpdatedAddress({ ...updatedAddress, [name]: value });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (errorMessage) return; // Prevent submission if invalid
        handleUpdateAddress(index, updatedAddress);
        setIndexForUpdate(null);
    };

    return (
        <div className="fixed z-[111] inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-sm">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                    Update Address
                </h2>

                <form onSubmit={handleSubmit} className="space-y-3">
                    <input type="text" name="name" placeholder="Full Name"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        onChange={handleChange} required />

                    <input type="text" name="mobileNumber" placeholder="Mobile Number"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        onChange={handleChange} required />

                    <input type="text" name="address1" placeholder="Address Line 1"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        onChange={handleChange} required />

                    <input type="text" name="address2" placeholder="Address Line 2 (Optional)"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        onChange={handleChange} />

                    <div className="grid grid-cols-2 gap-3">
                        <input type="text" name="city" placeholder="City"
                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                            onChange={handleChange} required />

                        <select name="state"
                            className="w-full h-12 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                            onChange={handleChange} required>
                            <option value="">Select State</option>
                            {states?.map((state, index) => (
                                <option key={index} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                        <input type="text" name="postalCode" placeholder="Postal Code"
                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                            maxLength={6} // Ensure user enters only 6 characters
                            onChange={handleChange} required />
                        <input type="text" name="country" placeholder="Country"
                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                            onChange={handleChange} required />
                    </div>

                    {errorMessage && (
                        <p className="text-red-500 text-sm">{errorMessage}</p>
                    )}

                    <input type="text" name="landmark" placeholder="Landmark"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        onChange={handleChange} />

                    <div className="flex justify-end space-x-3 mt-4">
                        <button type="button"
                            className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500 transition-all"
                            onClick={() => setIndexForUpdate(null)}>
                            Cancel
                        </button>
                        <button type="submit"
                            className={`px-4 py-2 rounded-lg transition-all ${errorMessage ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600 text-white"}`}
                            disabled={!!errorMessage}>
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateForm;
