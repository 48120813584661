import React, { useEffect, useRef, useState } from "react";
import AccountSidebar from "../SideBar";
import { EmailOtp } from "./verifyOtpForEmail";
import { PostRequest } from "../../../api/Request";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../utils/toast";
import Loader from "../../../common/loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { MdOutlineVerified } from "react-icons/md";
import { asyncUpdateProfile } from "../../../actions/userAction";
import { setUser } from "../../../slice/UserSlice";
import { Helmet } from "react-helmet";

const Profile: React.FC = () => {
  const userDetail = useSelector((state: RootState) => state?.user?.user);

  const [profile, setProfile] = useState({
    username: userDetail?.username ?? "", // Default to an empty string if `userDetail` is null or undefined
    gender: userDetail?.gender ?? "",
    email: {
      id: userDetail?.email ?? "", // Default to empty string
      isVerified: userDetail?.email ? true : false, // Check if email exists
    },
  });
  
  const userIsLoggedIn = useSelector((state:RootState)=>state.user.isLoggedIn);
  const isLoadingForUser = useSelector((state:RootState)=>state.user.isLoggedIn);
  const dispatch=useDispatch<AppDispatch>()
  const [isLoadingForVerfiyingOtp, setIsLoadingForVerfiyingOtp] =
    useState<boolean>(false);
  const [
    isLoadingForEmailWhileSendingOtp,
    setIsLoadingForEmailWhileSendingOtp,
  ] = useState(() => false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showOtpForEmail, setShowOtpForEmail] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [timer, setTimer] = useState(30);
  const [showTimer, setShowTimer] = useState(true);
  const [emailOTP, setEmailOTP] = useState<string[]>(new Array(6).fill(""));

  const startTimer = () => {
    setShowTimer(true);
    setTimer(30);
  };

  const handleCloseEmailModal = () => {
    setShowOtpForEmail(() => false);
    setEmailOTP(() => new Array(6).fill(""));
    setTimer(() => 30);
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setShowTimer(false);
    }
  }, [timer]);

  const emailOTPHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newEmailOTP: string[] = [...emailOTP];

    // setErrorOfOTP('');
    if (key === "Backspace") {
      if (newEmailOTP[index] === "") {
        setActiveOTPIndex(index - 1);
        newEmailOTP[index - 1] = "";
      } else {
        newEmailOTP[index] = "";
        setEmailOTP(newEmailOTP);
        setActiveOTPIndex(index);
      }
    } else if (key.match("[0-9]")) {
      newEmailOTP[index] = key;
      setActiveOTPIndex(index + 1);
      setEmailOTP(newEmailOTP);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${seconds < 10 ? "0" + seconds : seconds}`;
  };

  //
  useEffect(() => {
    setProfile(() => {
      return {
        username: userDetail?.username ?? "", // Default to an empty string if `userDetail` is null or undefined
        gender: userDetail?.gender ?? "",
        email: {
          id: userDetail?.email ?? "", // Default to empty string
          isVerified: userDetail?.email ? true : false, // Check if email exists
        },
      };
    });
  }, [userDetail]);

  const validateEmail = (email: string) => {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };



  const updateProfile = async () => {
    let payload: any = {};
  
    if (profile.username) {
      payload.username = profile.username;
    }
    if (profile.gender) {
      payload.gender = profile.gender;
    }
  
    if (validateEmail(profile.email.id) && profile.email.isVerified) {
      payload.email = profile.email.id;
    } else if (profile.email.id.length > 0 && !validateEmail(profile.email.id)) {
      return showErrorToast("Enter a valid mail");
    } else if (validateEmail(profile.email.id) && !profile.email.isVerified) {
      return showErrorToast("Verify Your Email");
    }
  
    setIsLoading(true);
    try {
      await dispatch(asyncUpdateProfile(payload)); // Ensure the update completes before setting loading to false
    } catch (error) {
      console.error("Profile update error:", error);
      showErrorToast("Failed to update profile");
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, [userIsLoggedIn, isLoadingForUser]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]:
        name === "email"
          ? { id: value, isVerified: prevProfile.email.isVerified }
          : value,
    }));
  };

  const sendOtpOnEmail = async () => {
    if (validateEmail(profile.email.id) && !profile.email.isVerified) {
      setIsLoadingForEmailWhileSendingOtp(() => true);
      let response = await PostRequest("send-email-otp", {
        email: profile.email.id,
      });

      if (response.status === 200) {
        setShowOtpForEmail(() => true);
        showSuccessToast("Otp sent successfully");
        await startTimer();
      }
      setIsLoadingForEmailWhileSendingOtp(() => false);
    } else {
      showErrorToast("Enter a valid email");
    }
  };

  const verifyEmail = async () => {
    setIsLoadingForVerfiyingOtp(() => true);
    try {
      // Make the API request to verify the email OTP
      const response = await PostRequest("verify-email-otp", {
        // email: profile.email.id,
        otp: emailOTP.join(""),
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get("redirect");
        // Update the profile state to mark the email as verified
        setProfile((prevState: any) => ({
          ...prevState,
          email: { ...prevState.email, isVerified: true },
        }));
        dispatch(
          setUser({
            username: profile?.username || "",
            number: userDetail?.number ?? "",
            gender: profile?.gender || "",
            email: profile?.email.id || "",
            addresses: userDetail?.addresses || [],
            _id: userDetail?._id ?? "",
            userIsLoggedIn: true,
          })
        );
        showSuccessToast("Email got verified");

        if (redirect === "cart") {
          navigate("/cart");
        }
        handleCloseEmailModal();
      }
    } catch (error: any) {
      // Handle any unexpected errors
      showErrorToast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "An error occurred while verifying your email. Please try again later."
      );
    }
    setIsLoadingForVerfiyingOtp(() => false);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex mt-[8rem] md:flex-row items-start mx-auto my-0  justify-center flex-wrap  mb-[1rem]">
        <div className="basis-[20%] sm:hidden lg:basis-[100%] mt-[1rem] border-r-[1px] border-r-[#ddd]">
          <AccountSidebar />
        </div>

        {showOtpForEmail && (
          <EmailOtp
            setEmailOTP={setEmailOTP}
            setShowOtpForEmail={setShowOtpForEmail}
            showOtpForEmail={showOtpForEmail}
            emailOTP={emailOTP}
            timer={timer}
            emailOTPHandleOnKeyDown={emailOTPHandleOnKeyDown}
            startTimer={startTimer}
            showTimer={showTimer}
            handleCloseEmailModal={handleCloseEmailModal}
            formatTime={formatTime}
            activeOTPIndex={activeOTPIndex}
            inputRef={inputRef}
            setTimer={setTimer}
            sendOtpOnEmail={sendOtpOnEmail}
            verifyEmail={verifyEmail}
            isLoadingForVerfiyingOtp={isLoadingForVerfiyingOtp}
          />
        )}

        <div className="flex-1 p-4 sm:p-0 basis-[50%] md:basis-[90%] md:mt-0 w-full">
          <div className="w-[90%] h-full bg-white rounded p-2 mx-auto sm:w-[95%] sm:p-0">
            <div className="mb-4">
              <h1 className="font-medium text-2xl sm:text-xl mb-6 sm:mb-3">
                My Profile
              </h1>
            </div>

            <div className="mb-4 flex flex-wrap justify-between items-center">
              <div className="basis-[45%] sm:basis-[100%]">
                <label
                  htmlFor="full_name"
                  className="block font-semibold text-sm text-gray-600 mb-1"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="full_name"
                  name="username"
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded focus:bg-white"
                  placeholder="Full Name"
                  aria-label="Full Name"
                  required
                  value={profile?.username}
                />
              </div>
              <div className="basis-[45%] sm:basis-[100%] sm:mt-[1rem]">
                <div className="mb-1 flex items-center justify-between relative">
                  <label
                    htmlFor="cus_email"
                    className="block font-semibold  text-sm text-gray-600 mb-1"
                  >
                    Email Address
                  </label>

                  {userDetail?.email && profile.email.isVerified ? (
                    <MdOutlineVerified className="text-green-500 text-xl" />
                  ) : (
                    <button
                      onClick={
                        profile.email.isVerified ? () => {} : sendOtpOnEmail
                      }
                      className="text-sm text-blue-500 hover:underline"
                    >
                      {profile.email.isVerified ? "" : "Verify"}
                    </button>
                  )}

                  {isLoadingForEmailWhileSendingOtp && (
                    <div className="absolute top-[10px] right-[38px]">
                      <Loader />
                    </div>
                  )}
                </div>
                <input
                  type="email"
                  id="cus_email"
                  name="email"
                  className="w-full px-4 py-2 border border-gray-300 rounded focus:bg-white"
                  placeholder="Your Email"
                  aria-label="Email"
                  required
                  onChange={handleChange}
                  readOnly={profile.email.isVerified ? true : false}
                  value={profile?.email.id}
                />
              </div>
            </div>
            <div className="flex justify-between items-center flex-wrap">
              <div className="basis-[45%] sm:basis-[100%]">
                <div className="mb-2 flex items-center basis-[45%] justify-between">
                  <label
                    htmlFor="cus_mobile"
                    className="block font-semibold text-sm text-gray-600 mb-1"
                  >
                    Mobile Number
                  </label>
                </div>
                <input
                  type="text"
                  id="cus_mobile"
                  name="cus_mobile"
                  className="w-full px-4 py-2 border border-gray-300 rounded focus:bg-white"
                  placeholder="Mobile Number"
                  aria-label="Mobile Number"
                  required
                  readOnly
                  defaultValue={userDetail?.number}
                />
              </div>

              <div className="mb-2 basis-[45%] sm:mt-[1rem]">
                <label className="block font-semibold text-sm text-gray-600 mb-1">
                  Your Gender
                </label>
                <div className="flex items-center basis-[100%] space-x-6">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio h-4 w-4 text-gray-700"
                      name="gender"
                      value="male"
                      onChange={handleChange}
                      checked={profile?.gender === "male"}
                    />
                    <span className="ml-2 text-sm text-gray-700">Male</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio h-4 w-4 text-gray-700"
                      name="gender"
                      value="female"
                      onChange={handleChange}
                      checked={profile?.gender === "female"}
                    />
                    <span className="ml-2 text-sm text-gray-700">Female</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio h-4 w-4 text-gray-700"
                      name="gender"
                      value="others"
                      onChange={handleChange}
                      checked={profile?.gender === "others"}
                    />
                    <span className="ml-2 text-sm text-gray-700">Others</span>
                  </label>
                </div>
              </div>
              <div className="basis-[100%] text-end sm:text-center">
                <button
                  onClick={updateProfile}
                  className="w-[15%] relative h-[3rem] sm:w-[40%] sm:p-[0.4rem] sm:mt-[1rem] text-white rounded-xl p-[0.7rem] text-sm mt-[2rem] bg-primary"
                >
                  {!isLoading ? (
                    "Update"
                  ) : (
                    <div className="absolute top-[-10px] right-[60%]">
                      <Loader />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
