import axios from "axios";
// const BASE_URL = "https://7111-103-100-219-232.ngrok-free.app/v1/";

const BASE_URL = "https://api.prod.cayroshop.com/v1/";

// const BASE_URL = "http://localhost:8080/v1/";

export default axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosRequest = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});
