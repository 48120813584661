import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaShoppingCart } from "react-icons/fa";
import Slider from "@mui/material/Slider";

import Loader from "../../common/loader/Loader";
import {
  filters,
  isLoadingForProduct,
  metaInfo,
  totalItemForProduct,
  updateSelectedFilter,
} from "../../slice/ProductSlice";
import { AppDispatch, RootState } from "../../store/store";
import { product } from "../../slice/ProductSlice";
import Pagination from "../../pagination/Pagination";
import { RxCross2 } from "react-icons/rx";
import { useMediaQuery } from "@react-hook/media-query";

import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  RenderHelmetForBrand,
  RenderHelmetForCat,
  RenderHelmetForSubCat,
} from "../../utils/Helmet";
import UpperBanner from "./UpperBanner";
import BottomDesc from "./BottomDesc";
import {
  asyncAddToCart,
  asyncFetchFilterFormattedBrand,
  asyncFetchFilterFormattedCat,
  asyncFetchFilterFormattedSubCat,
  asyncGetProductByFilters,
  asyncUpdateQuantity,
} from "../../actions/productAction";
import { asyncRemoveItem } from "../../actions/searchProductAction";
import { FiMinus, FiPlus } from "react-icons/fi";

const ProductListing = () => {
  const metaInfoForProductListing = useSelector(metaInfo);
  const categoryDescription = useSelector(
    (state: RootState) => state.product.categoryDescription
  );
  const isMobile = useMediaQuery("(max-width: 780px)");
  const [userChangedPrice, setUserChangedPrice] = useState(false);

  const [, setLoadingProducts] = useState<{
    [key: string]: boolean;
  }>({});
  const totalItems = useSelector(totalItemForProduct);
  const navigate = useNavigate();
  const productFilter = useSelector(filters);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const query: any = new URLSearchParams(location.search);
  const { categoryName, subcategoryName, brandName }: any = useParams();
  const subCategoryId: any = query.get("sub-category-id");
  const allProduct = useSelector(product);
  const { bannerImage } = useSelector((state: RootState) => state.product);
  const { setMessage } = useSelector((state: RootState) => state.product);
  const [openCategory, setOpenCategory] = useState<any>([]);
  const isLoading = useSelector(isLoadingForProduct);
  const [showFilterOnMobile, setShowFilterOnMobile] = useState<boolean>(false);
  const [loadingProductId, setLoadingProductId] = useState<string | null>(null);
  const [cart, setCart] = useState<{ [key: string]: number }>({});
  const [sortBy, setSortBy] = useState("");

  const useDebounce = (value: any, delay: any) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const [priceRange, setPriceRange] = useState<[number, number] | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      productFilter?.minPrice !== undefined &&
      productFilter?.maxPrice !== undefined
    ) {
      setPriceRange([productFilter.minPrice, productFilter.maxPrice]);
    }
  }, [productFilter]); // Runs whenever productFilter updates

  const debouncedPriceRange = useDebounce(priceRange, 2000); // Debounce for smooth updates

  useEffect(() => {
    if (!userChangedPrice || !debouncedPriceRange) return;

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("minPrice", String(debouncedPriceRange[0]));
    searchParams.set("maxPrice", String(debouncedPriceRange[1]));

    navigate(`?${searchParams.toString()}`);
  }, [debouncedPriceRange, navigate, location.search, userChangedPrice]);

  // Handle slider value change
  const handlePriceChange = (_event: Event, newValue: number | number[]) => {
    setPriceRange(newValue as [number, number]);
    setUserChangedPrice(true);
  };
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortBy(event.target.value); // Update the sortBy state when the user selects a new option
  };

  useEffect(() => {
    const brandId: any = query.get("brand-id");
    const searchParams = new URLSearchParams(location.search);
    const filters: { [key: string]: any } = {};

    const minPrice = searchParams.get("minPrice");
    const maxPrice = searchParams.get("maxPrice");
    const page = searchParams.get("page");
    searchParams.forEach((value, key) => {
      if (
        [
          "category_id",
          "sub-category-id",
          "page",
          "categoryName",
          "minPrice",
          "maxPrice",
        ].includes(key.toLowerCase())
      ) {
        return; // Skip this iteration if the key matches any of the excluded keys
      }
      if (searchParams.getAll(key).length > 1) {
        filters[key] = searchParams.getAll(key); // If multiple values exist for this key
      } else {
        filters[key] = value; // Single value
      }
    });

    dispatch(
      asyncGetProductByFilters({
        navigate,
        subCategoryIdForProduct: subCategoryId,
        categoryIdForProduct: categoryName,
        ...filters,
        maxPrice: maxPrice || undefined,
        minPrice: minPrice || undefined,
        sortBy,
        pageForProduct: page || 1,
        brandId,
      })
    );

    window.scrollTo(0, 0);
  }, [location.search, dispatch, sortBy, categoryName, subCategoryId]);

  useEffect(() => {
    const subCategoryId: any = query.get("sub-category-id");
    const brandId: any = query.get("brand-id");
    if (categoryName) {
      dispatch(asyncFetchFilterFormattedCat(categoryName));
    }
    if (subcategoryName) {
      dispatch(asyncFetchFilterFormattedSubCat(subCategoryId));
    }
    if (brandName) {
      dispatch(asyncFetchFilterFormattedBrand(brandId));
    }
  }, [
    query.get("sub-category-id"),
    // query.get("category_id"),
    categoryName,
    subcategoryName,
    query.get("brand-id"),
  ]);

  const filterContainerRef = useRef<HTMLDivElement>(null);
  const preserveScrollPosition = () => {
    // Use optional chaining to safely access scrollTop
    const scrollPosition =
      filterContainerRef.current?.scrollTop || window.scrollY;
    return () => {
      if (filterContainerRef.current) {
        filterContainerRef.current.scrollTop = scrollPosition;
      } else {
        window.scrollTo(0, scrollPosition);
      }
    };
  };

  const handleCheckboxChange = (filterField: string, filterValue: any) => {
    const restoreScroll = preserveScrollPosition();
    dispatch(updateSelectedFilter({ filterField, filterValue }));

    const searchParams = new URLSearchParams(location.search);

    const updateSearchParams = (key: string, value: any) => {
      let existingValues: string[];
      let lowercasedValue = value;

      if (key === "Brands") {
        existingValues = searchParams.getAll("brand");
        key = "brand";
        lowercasedValue = value.toLowerCase();
      } else {
        existingValues = searchParams.getAll(key);
      }

      if (!existingValues.includes(lowercasedValue)) {
        searchParams.append(key, lowercasedValue); // Append if not included
      } else {
        const updatedValues = existingValues.filter(
          (item) => item !== lowercasedValue
        );
        searchParams.delete(key); // Clear existing values
        updatedValues.forEach((item) => searchParams.append(key, item)); // Re-add remaining values
      }
    };

    updateSearchParams(filterField, filterValue);

    navigate(`?${searchParams.toString()}`);
    requestAnimationFrame(restoreScroll);
  };

  const isCheckedOrNot = (key: string, item: any) => {
    const searchParams = new URLSearchParams(location.search);

    const normalizedKey = key === "Brands" ? "brand" : key;

    // Get all the query parameter values for the key
    const existingValues = searchParams.getAll(normalizedKey);

    return existingValues.includes(item);
  };

  // Updated handleAddToCart function
  const handleAddToCart = async (product: any, index: number) => {
    const productId = product._id;
    setLoadingProductId(productId);
    setLoadingProducts((prev) => ({ ...prev, [productId]: true }));
    let flag = true;
    try {
      await dispatch(asyncAddToCart(product, flag));

      // Update loading state after cart is updated
      setLoadingProducts((prev) => ({ ...prev, [productId]: false }));

      // Optionally set cart state if required (for UI updates)
      setCart((prevCart) => ({ ...prevCart, [productId]: 1 }));
    } catch (error) {
      console.error("Failed to add product to cart:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  const handleUpdateQuantity = async (
    product: any,
    index: number,
    quantity: number
  ) => {
    const productId = product._id;
    setLoadingProductId(productId);

    try {
      if (quantity === 0) {
        await dispatch(asyncRemoveItem(product));
        setCart((prevCart: any) => ({ ...prevCart, [productId]: quantity }));
      } else {
        await dispatch(asyncUpdateQuantity(product, index, quantity));
        setCart((prevCart: any) => ({ ...prevCart, [productId]: quantity }));
      }
    } catch (error) {
      console.error("Failed to update quantity:", error);
    } finally {
      setLoadingProductId(null);
    }
  };
  const [showMoreState, setShowMoreState] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleShowMore = (key: string) => {
    const restoreScroll = preserveScrollPosition();
    setShowMoreState((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle the specific key's value
    }));
    requestAnimationFrame(restoreScroll);
  };
  const getFilterLabel = (key: any, item: any, idx: any, prices: any) => {
    if (key === "Brands") {
      return item.name;
    } else {
      return item;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickOnFilter = () => {
    setShowFilterOnMobile(() => true);
  };

  const handleClickOnClearAllFilter = () => {
    if (categoryName) {
      navigate(`/category/${categoryName}?page=1`);
    } else if (subCategoryId) {
      navigate(`/subcategory/${categoryName}?page=1`);
    } else if (brandName) {
      navigate(`/brand/${brandName}?page=1`);
    }
    setShowFilterOnMobile(false);
  };

  const calculateDiscountPercentage = (
    salePrice: number,
    regularPrice: number
  ) => {
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };

  console.log(brandName);
  return (
    <>
      {categoryName && (
        <RenderHelmetForCat metaInformations={metaInfoForProductListing} />
      )}

      {subcategoryName && (
        <RenderHelmetForSubCat metaInformations={metaInfoForProductListing} />
      )}

      {brandName && (
        <RenderHelmetForBrand metaInformations={metaInfoForProductListing} />
      )}

      <UpperBanner
        categoryName={categoryName}
        brands={productFilter.Brands}
        brandName={brandName}
        subcategoryName={subcategoryName}
        bannerImage={bannerImage}
      />
      <div className="max-w-[95%] sm:w-[100%] mx-auto  relative sm:mt-[2rem] p-2 sm:p-0">
        <div className="catalog-toolbar flex justify-between items-center space-x-12 sm:space-x-2">
          <button
            className="mobile-catalog-toolbar__filter-button  sm:sm:text-[0.8rem] bg-transparent text-[#505050] border-0 flex items-center mr-2"
            data-toggle="off-canvas"
            data-target="mobile-filter-sidebar-panel"
            onClick={isMobile ? handleClickOnFilter : () => {}}
          >
            <span className="motta-svg-icon motta-svg-icon--filter">
              <svg
                width="20"
                height="20"
                aria-hidden="true"
                role="img"
                focusable="false"
                viewBox="0 0 32 32"
              >
                <path d="M8 14.4h3.2v-9.6h-3.2v3.2h-4.8v3.2h4.8z"></path>
                <path d="M24 17.6h-3.2v9.6h3.2v-3.2h4.8v-3.2h-4.8z"></path>
                <path d="M14.4 8h14.4v3.2h-14.4v-3.2z"></path>
                <path d="M3.2 20.8h14.4v3.2h-14.4v-3.2z"></path>
              </svg>
            </span>
            Filter
            <span className="count ml-8"></span>
          </button>

          <p className="sm:text-[0.8rem] sm:hidden text-nowrap text-gray-600">
            <p className="font-semibold">
              Search Listing (Showing{" "}
              <span className="font-semibold">{allProduct.length} </span>{" "}
              products of
              <span className="font-semibold"> {totalItems}</span> products)
            </p>
          </p>
          <div className="flex items-center sm:text-[0.8rem] space-x-2 outline-none text-nowrap">
            <span>Sort by :</span>

            <select
              className="orderby p-2 sm:p-0 rounded outline-none "
              aria-label="Shop order"
              value={sortBy} // Set the selected value
              onChange={handleSortChange} // Handle change event
            >
              <option value="">Default</option>
              <option value="priceAsc">Low to High</option>
              <option value="priceDesc">High to Low</option>
              <option value="date">Latest</option>
              <option value="bestSellers">Best Sellers</option>
            </select>
          </div>
        </div>
        <hr className="sm:mb-[0.5rem] mt-1" />

        <div className="flex flex-wrap justify-between w-[100%] sm:justify-start max-h-full ">
          {/* filters */}
          <div
            className={
              showFilterOnMobile
                ? "fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-10 flex justify-center items-center"
                : "basis-[20%]"
            }
          >
            <aside
              className={
                showFilterOnMobile
                  ? "top-[101px] max-h-[57vh] w-[74%] absolute overflow-y-auto border-[1.3px] border-solid border-[#ddd] rounded-lg px-[0.8rem] mt-[0.75rem] bg-[#F7F4FF] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
                  : "basis-[20%] sm:hidden max-h-[1400px] lg:max-h-full xl:max-h-full xxl:max-h-[1300px] overflow-y-auto border-[1.3px] border-solid border-[#ddd] rounded-lg px-[0.4rem] mt-[0.75rem] bg-[#F7F4FF] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
              }
            >
              {Object.entries(productFilter).length > 0 && (
                <p
                  className={
                    isMobile
                      ? "flex justify-between items-center mt-[0.5rem]"
                      : "flex justify-end items-center"
                  }
                >
                  <span
                    onClick={handleClickOnClearAllFilter}
                    className="text-sm text-[#3E0E9A] mt-[0.5rem] text-end cursor-pointer"
                  >
                    Reset All
                  </span>
                  {isMobile && (
                    <span
                      onClick={() => setShowFilterOnMobile(false)}
                      className="text-[1.2rem] text-red-600 mt-[0.5rem] text-end cursor-pointer"
                    >
                      <RxCross2 />
                    </span>
                  )}
                </p>
              )}
              <ul className="space-y-2 font-medium py-4">
                {Object.entries(productFilter).length > 0 &&
                  Object.entries(productFilter).map(([key, value], index) => {
                    const items = Array.isArray(value) ? value : [];
                    const visibleItems = showMoreState[key]
                      ? items
                      : items.slice(0, 7);
                    const hasMoreItems = items.length > 7;

                    if (key !== "maxPrice" && key !== "minPrice") {
                      return (
                        <li key={index} className="relative">
                          <div className="p-1 rounded-lg">
                            <span className="text-[0.95rem] font-semibold text-gray-900">
                              {key === "Display Quality"
                                ? "Display Quality"
                                : key}
                            </span>
                          </div>
                          <ul className="mt-1 grid grid-cols-1">
                            {visibleItems.map((item, idx) => (
                              <li
                                key={idx}
                                className="px-1 py-1 text-gray-700 hover:bg-gray-100 rounded-md text-sm transition-colors"
                              >
                                <label className="flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={isCheckedOrNot(
                                      key,
                                      key === "Brands" ? item._id : item
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        key,
                                        key === "Brands" ? item._id : item
                                      )
                                    }
                                    className="mr-2 h-4 w-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
                                  />
                                  <span className="truncate">
                                    {getFilterLabel(key, item, idx, value)}
                                  </span>
                                </label>
                              </li>
                            ))}
                            {hasMoreItems && (
                              <li className="px-3 py-2 text-sm text-blue-600 hover:text-blue-800 cursor-pointer">
                                <button onClick={() => toggleShowMore(key)}>
                                  {showMoreState[key]
                                    ? "Show Less"
                                    : "Show More"}
                                </button>
                              </li>
                            )}
                          </ul>
                          <hr className="my-2 border-gray-200" />
                        </li>
                      );
                    } else if (key === "maxPrice") {
                      return (
                        <li key={index} className="relative">
                          <div className="p-1 rounded-lg">
                            <span className="text-[0.95rem] font-semibold text-gray-900">
                              Price Range
                            </span>
                          </div>
                          <div className="mt-2 px-3">
                            <div className="relative flex flex-col items-center w-full">
                              <Slider
                                value={priceRange ?? [0, 1000]}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={productFilter.minPrice}
                                max={productFilter.maxPrice}
                                sx={{
                                  color: "#3F0E9E",
                                  height: 6,
                                  "& .MuiSlider-thumb": {
                                    width: 16,
                                    height: 16,
                                    backgroundColor: "#3F0E9E",
                                    border: "2px solid #fff",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                  },
                                  "& .MuiSlider-track": {
                                    height: 4,
                                    background:
                                      "linear-gradient(to right, #3F0E9E, #6B46C1)",
                                  },
                                  "& .MuiSlider-rail": {
                                    height: 4,
                                    backgroundColor: "#e5e7eb",
                                    opacity: 0.8,
                                  },
                                }}
                              />
                              <div className="flex justify-between w-full text-xs text-gray-600 mt-2">
                                <span>₹{priceRange?.[0]}</span>
                                <span>₹{priceRange?.[1]}</span>
                              </div>
                            </div>
                          </div>
                          <hr className="my-2 border-gray-200" />
                        </li>
                      );
                    }
                    return null;
                  })}
              </ul>
            </aside>
          </div>
          <div className="flex flex-wrap justify-evenly pb-8 basis-[80%] sm:basis-full sm:pb-4 ">
            {isLoading ? (
              <div className="absolute top-[30%] sm:static sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center sm:h-[50vh] sm:left-[45%] left-[60%]">
                <Loader />
              </div>
            ) : allProduct.length > 0 ? (
              allProduct.map((product: any, index: number) => {
                const productId = product._id;
                const quantity = cart[productId] || 0;
                return (
                  <div
                    key={index}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                    className="m-[0.7rem] max-h-[400px] sm:m-[0.4rem] basis-[22%]  sm:py-[0.7rem] sm:px-[0.55rem] py-[1rem] pt-[0.5rem] px-[0.7rem] rounded-xl flex flex-col sm:basis-[42%] sm:w-[calc(50%-1.4rem)] relative"
                  >
                    <Link
                      to={`/product?name=${
                        product.productName
                          .replace(/[^a-zA-Z0-9]/g, "-") // Replace all non-alphanumeric characters with dashes
                          .replace(/-+/g, "-") // Replace multiple dashes with a single dash
                          .toLowerCase() // Optional: Convert to lowercase for a cleaner URL
                      }&product_id=${product._id}`}
                      className="flex sm:mt-[0.9rem] justify-center items-center"
                    >
                      <h4 className="!text-[#CD0B39] font-semibold ml-[0.3rem] !text-sm absolute right-1 z-[9] top-1 bg-[#F8F4FF] p-[2px] rounded-md">
                        {calculateDiscountPercentage(
                          Number(product?.salePrice),
                          Number(product?.regularPrice)
                        ) + "% off"}
                      </h4>
                      <LazyLoadImage
                        effect="blur"
                        src={
                          product?.images?.length > 0 && product?.images[0]?.url
                        }
                        alt={product.productName}
                        className="object-contain w-[100%] h-[200px] sm:h-[120px] transition duration-300 ease-in-out hover:scale-105"
                      />
                    </Link>
                    <Link
                      to={`/product?name=${product.productName}&product_id=${product._id}`}
                    >
                      <h4 className="text-normal w-[220px] sm:w-[auto] line-clamp-3 text-left mt-[0.5rem] sm:text-[0.9rem] text-gray-900 h-[3rem] overflow-hidden">
                        {product.productName}
                      </h4>
                    </Link>
                    <div className="text-[1rem] sm:text-[0.9rem] text-[#0F1111] font-semibold text-left mt-1">
                      <h2>
                        ₹{product?.salePrice}
                        <h3 className="text-gray-500 text-xs ml-[0.4rem] inline-block line-through">
                          ₹{product?.regularPrice}
                        </h3>
                      </h2>
                    </div>
                    {product.totalStock === 0 ? (
                      <div className="mt-[20px] flex justify-center items-center cursor-not-allowed w-full">
                        <button className="bg-gray-400 text-white text-center w-3/4 sm:h-[25px] relative rounded-lg p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] cursor-not-allowed">
                          Out Of Stock
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center  gap-5 justify-center w-full">
                        {quantity > 0 ? (
                          // 🔢 Show Quantity Controls if Product is Already in Cart
                          <div className="flex items-center justify-between w-3/4  rounded-lg p-1 mt-[20px]">
                            <button
                              onClick={() =>
                                handleUpdateQuantity(
                                  product,
                                  index,
                                  quantity - 1
                                )
                              }
                              className="p-1 rounded-full border bg-gray-200 hover:bg-gray-300 transition disabled:opacity-50"
                              disabled={loadingProductId === productId}
                            >
                              <FiMinus size={16} />
                            </button>
                            <span className="text-lg font-semibold">
                              {quantity}
                            </span>
                            <button
                              onClick={() =>
                                handleUpdateQuantity(
                                  product,
                                  index,
                                  quantity + 1
                                )
                              }
                              className="p-1 rounded-full border bg-gray-200 hover:bg-gray-300 transition disabled:opacity-50"
                              disabled={
                                loadingProductId === productId ||
                                quantity >= product.totalStock
                              }
                            >
                              <FiPlus size={16} />
                            </button>
                          </div>
                        ) : loadingProductId === productId ? (
                          // ⏳ Loader While Adding to Cart
                          <div className="flex items-center justify-center mt-[20px]">
                            <Loader />
                          </div>
                        ) : (
                          // 🛒 Add to Cart Button (If Product is NOT in Cart)
                          <button
                            onClick={() => handleAddToCart(product, index)}
                            className="whitespace-nowrap mt-5 flex items-center justify-center gap-2 w-3/4 relative rounded-full border border-purple-500 text-[#3F0E9E] p-1 text-sm md:text-[12px] hover:bg-purple-100 transition disabled:opacity-50"
                            disabled={loadingProductId === productId}
                          >
                            <FaShoppingCart size={15} />
                            <span>Add to cart</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <p className="flex justify-center w-[100%] mt-[3rem] text-red-500 font-bolder">
                {setMessage}
              </p>
            )}
          </div>
        </div>
        {allProduct.length > 0 && <Pagination />}
      </div>
      <BottomDesc
        metaInfo={metaInfoForProductListing}
        categoryDescription={categoryDescription}
      />
    </>
  );
};

export default ProductListing;
