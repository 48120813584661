import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import {
  allProduct,
  setCartLength,
  clearFromLocalStorage,
} from "../../slice/CartSlice";
import Lottie from "lottie-react";
import carticon from "../../loti/carticon.json";
import emptyCart from "../../loti/emptyCart.json";
import Loader from "../../common/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  asyncCheckValidCoupon,
  asyncClearCart,
  asyncFetchAllCoupons,
  getAllProductInCart,
} from "../../actions/cartAction";
import {
  asyncRemoveItem,
  asyncUpdateQuantity,
} from "../../actions/productAction";
import { logout } from "../../slice/UserSlice";

const ShoppingCart: any = () => {
  const {
    shippingCharges,
    total,
    discount,
    totalMRP,
    coupons,

    couponSavings,
  } = useSelector((state: RootState) => state.cart);
  const [coupon, setCoupon] = useState("");
  const [discountOffer, setDiscountOffer] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [savedAmount, setSavedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleApplyCoupon = () => {
    setShowModal(false);
  };
  const [isLoadingForCheckingOut, setIsLoadingForCheckingOut] =
    useState<boolean>(false);
  const userDetail: any = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch<AppDispatch>();
  const allProducts = useSelector(allProduct);

  const [shippingAddress, setShippingAddress] = useState<any>();
  // const [isFetchingCart, setIsFetchingCart] = useState<boolean>(false);
  const { loading } = useSelector((state: RootState) => state.cart);

  useEffect(() => {
    dispatch(getAllProductInCart());
  }, [dispatch]);

  const productIds = allProducts.map((product: any) => product._id);

  useEffect(() => {
    if (showModal) {
      dispatch(asyncFetchAllCoupons());
    }
  }, [showModal, dispatch]);

  const handleVerifyCoupon = async () => {
    if (!coupon) {
      setError("Please enter a coupon code");
      return;
    }

    setIsLoading(true); // Start loading
    setIsClicked(true); // Mark as clicked

    try {
      await dispatch(asyncCheckValidCoupon(productIds, coupon, discountOffer));
      setIsVerified(true); // Set verified to true on success
    } catch (error) {
      setError("Invalid coupon code");
      setIsVerified(false); // Reset verified status on error
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const applyCouponFromList = (code: any, discounted: any) => {
    setCoupon(code); // Set the
    setDiscountOffer(discounted);
    setSavedAmount(discount);
  };

  const handleUpdateQuantity = async (
    product: any,
    index: number,
    delta: number
  ) => {
    const productId = product._id;
    const newQuantity = product.quantityByUser + delta; // Increment or decrement based on current quantity

    // Start the loading state for the product being updated
    setIsLoading(true);

    try {
      if (newQuantity <= 0) {
        // If new quantity is 0 or less, remove the product from the cart

        // Call asyncRemoveItem to remove product from cart for both logged-in and non-logged-in users
        await dispatch(asyncRemoveItem(product, index, 0)); // Pass 0 as quantity to trigger removal
        localStorage.removeItem("couponSavings");
      } else {
        // Otherwise, update the product quantity in the cart
        await dispatch(asyncUpdateQuantity(product, index, newQuantity)); // Dispatch async action to update quantity
      }
    } catch (error) {
      console.error("Failed to update quantity:", error);
    } finally {
      // End the loading state after the async operation is complete
      setIsLoading(false);
    }
  };

  const handleClearCart = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      // Dispatch the clearCart action
      await dispatch(asyncClearCart());
      await dispatch(clearFromLocalStorage());
      const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");
      await dispatch(setCartLength(cartItems.length));
    } catch (error) {
      console.error("Error clearing cart:", error);
    } finally {
      setIsLoading(false); // Reset loading state after the action
    }
  };

  useEffect(() => {
    if (userDetail.addresses.length > 0) {
      setShippingAddress(() => {
        return userDetail.addresses[0];
      });
    }
  }, []);

  if (loading) {
    <div className="wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center flex-col">
      <Loader />
    </div>;
  }

  if (!loading) {
    return (
      <div
        style={{ marginTop: "7rem" }}
        className={
          allProducts?.length > 0
            ? "wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8"
            : "wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center flex-col"
        }
      >
        <h1 className="text-[1.8rem] sm:text-[1.3rem] sm:ml-[-8rem] mt-[-1rem] sm:mt-[-1rem] w-[100%] flex items-center">
          <Lottie
            animationData={carticon}
            style={{
              width: "80px",
              height: "100px",
              marginRight: "-1.4rem",
            }}
          />
          Cart
        </h1>
        {isLoading && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <Loader /> {/* Your loader component */}
          </div>
        )}

        {allProducts?.length > 0 ? (
          <div className="flex flex-row md:flex-col flex-wrap justify-between ">
            <div className="w-full basis-[70%] md:w-full pr-4 border-[1.3px] md:border-none rounded-lg">
              <div className="overflow-x-auto max-h-[500px]">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 sticky top-0 z-10">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Products
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Product Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        M.R.P
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        GST
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 ">
                    {allProducts?.length > 0 &&
                      allProducts?.map((product: any, index: number) => (
                        <tr key={product._id}>
                          <td className="px-6 py-4">
                            <Link
                              to={`/product?name=${product.productName}&product_id=${product._id}`}
                            >
                              <LazyLoadImage
                                effect="blur"
                                className="w-24 h-24 md:w-16 md:h-16 sm:w-12 sm:h-12 object-contain"
                                src={product?.images[0]?.url}
                                alt={product.productName}
                              />
                            </Link>
                          </td>
                          <td className="px-6 py-4 md:w-1/3">
                            <Link
                              to={`/product?name=${product.productName}&product_id=${product._id}`}
                              className="text-sm font-medium text-gray-900 md:text-[12px] sm:text-[8px]"
                            >
                              {product.productName}
                            </Link>
                          </td>
                          <td className="px-6 py-4">
                            <span className="text-sm font-medium text-gray-900">
                              ₹{product.regularPrice}
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <span className="text-sm font-medium text-gray-900">
                              ₹{product.salePrice}
                            </span>
                          </td>
                          <td className="px-2 py-4 flex-row">
                            {/* Decrement Button */}
                            <button
                              className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                              onClick={() =>
                                handleUpdateQuantity(product, index, -1)
                              } // Pass -1 for decrement
                            >
                              <svg
                                className="flex-shrink-0 size-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M5 12h14"></path>
                              </svg>
                            </button>

                            {/* Quantity Input */}
                            <input
                              type="number"
                              className="w-8 text-center border-gray-300 rounded-md"
                              value={product?.quantityByUser}
                              readOnly
                            />

                            {/* Increment Button */}
                            <button
                              className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                              onClick={() =>
                                handleUpdateQuantity(product, index, 1)
                              } // Pass +1 for increment
                            >
                              <svg
                                className="flex-shrink-0 size-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M5 12h14"></path>
                                <path d="M12 5v14"></path>
                              </svg>
                            </button>
                          </td>

                          <td className="px-6 py-4">
                            <span className="text-sm font-medium text-gray-900">
                              {product.gst}%
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <span className="text-sm font-medium text-gray-900">
                              ₹{product.total}
                            </span>
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td colSpan={8} className="px-6 py-4 text-right">
                        <button
                          className="ml-2 px-4 py-2 bg-red-600 rounded-lg text-white"
                          onClick={handleClearCart}
                        >
                          Clear Cart
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="md:w-full basis-[18%] max-h-[400px] w-4/12 md:mt-4 flex-1 rounded-xl border border-gray-200 shadow-sm ml-2 bg-white">
              <div className="p-6 space-y-4">
                {/* Coupon Button */}
                {/* <button
                  onClick={() => setShowModal(true)}
                  className="w-full py-2.5 px-4 border border-primary text-primary rounded-lg hover:bg-primary hover:text-white text-sm font-semibold transition-colors shadow-sm"
                >
                  Apply Coupon
                </button> */}

                <div className="space-y-3 text-sm">
                  <p className="flex justify-between">
                    <span className="font-medium">Total MRP</span>
                    <span>₹{totalMRP?.toFixed(2)}</span>
                  </p>
                  <hr />

                  <p className="flex justify-between">
                    <span className="font-medium">Discount on MRP</span>
                    <span className="text-green-600">
                      -₹{discount?.toFixed(2)}
                    </span>
                  </p>
                  <hr />

                  <p className="flex justify-between">
                    <span className="font-medium">Shipping Charges</span>
                    <span>
                      <span className="line-through text-gray-400">
                        ₹{shippingCharges.toFixed(2)}
                      </span>
                      <span className="ml-2 text-green-600">₹0</span>
                    </span>
                  </p>
                  <hr />
                  {couponSavings.code && (couponSavings.discount || 0) > 0 && (
                    <p className="flex justify-between">
                      <span className="font-medium text-[14px]">
                        Coupon Discount
                      </span>
                      <span className="ml-2 text-green-600">
                        - ₹{(couponSavings.discount || 0).toFixed(2)}
                      </span>
                    </p>
                  )}

                  <div className="text-green-600 font-semibold text-sm mb-2 bg-green-100 rounded-lg p-2 text-center">
                    🎉 You saved ₹{discount.toFixed(2)}!
                  </div>

                  <p className="flex justify-between text-lg font-semibold">
                    <span>Grand Total</span>
                    <span>₹{total.toFixed(2)}</span>
                  </p>
                </div>
              </div>

              <Link
                to="/checkout"
                onClick={() => {
                  if (!localStorage.getItem("userToken")) {
                    localStorage.setItem("isLoggedIn", "false");
                    dispatch(logout());
                  }
                }}
                className="block w-[85%] mx-auto mb-4 py-2 text-sm text-center bg-primary text-white rounded-lg font-medium hover:bg-primary/90 transition-colors shadow-md"
              >
                Proceed to Checkout
                {isLoadingForCheckingOut && (
                  <div className="absolute top-[-18px] sm:static sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center sm:h-[50vh] sm:left-[45%] right-[21%]">
                    <Loader />
                  </div>
                )}
              </Link>

              {/* Modal */}
              {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white rounded-lg w-[480px] max-h-[90vh] overflow-hidden shadow-2xl transition-transform transform scale-100">
                    <div className="flex justify-between items-center p-4 border-b bg-gray-50">
                      <h2 className="text-lg font-semibold">Apply Coupon</h2>
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-gray-500 hover:text-red-600 text-xl transition-colors"
                      >
                        ✕
                      </button>
                    </div>

                    <div className="p-4 border-b">
                      <div className="flex gap-2">
                        <div className="relative flex-1">
                          <input
                            type="text"
                            value={coupon}
                            onChange={(e) => {
                              setCoupon(e.target.value.toUpperCase());
                              setError("");
                            }}
                            placeholder="Enter coupon code"
                            className="w-full p-3 border rounded-lg uppercase text-sm focus:border-primary focus:ring-1 focus:ring-primary transition"
                          />

                          {error && (
                            <p className="text-red-500 text-xs mt-1">{error}</p>
                          )}

                          {isVerified && (
                            <svg
                              className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          )}
                        </div>
                        <button
                          onClick={handleVerifyCoupon}
                          className={`bg-primary text-white px-6 rounded-lg text-sm font-medium transition shadow-sm flex items-center justify-center ${
                            isLoading
                              ? "bg-primary/70 cursor-not-allowed"
                              : "hover:bg-primary/90"
                          }`}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <svg
                              className="animate-spin h-4 w-4 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v8H4z"
                              ></path>
                            </svg>
                          ) : (
                            "CHECK"
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="overflow-y-auto max-h-[400px]">
                      <div className="p-4">
                        <h3 className="text-sm font-semibold mb-4">
                          Available Coupons
                        </h3>
                        <div className="space-y-4">
                          {coupons?.map((coupon: any, index: number) => {
                            const formattedExpiryDate = new Date(
                              coupon.expiryDate
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            });

                            return (
                              <div
                                key={index}
                                className="border rounded-lg p-4 hover:border-primary cursor-pointer transition shadow-sm hover:shadow-md"
                                onClick={() =>
                                  applyCouponFromList(
                                    coupon.code,
                                    coupon.discount
                                  )
                                }
                              >
                                <div className="flex justify-between items-start mb-2">
                                  <div>
                                    <p className="text-sm text-gray-600 mt-1">
                                      {coupon.code}
                                    </p>
                                    <span className="font-semibold text-sm text-primary">
                                      Rs:{coupon.discount}
                                    </span>
                                    <p className="text-sm text-gray-600 mt-1">
                                      {coupon.name}
                                    </p>
                                  </div>
                                  <button
                                    className="text-primary text-sm font-medium hover:text-primary/80 transition-colors"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      applyCouponFromList(
                                        coupon.code,
                                        coupon.discount
                                      );
                                    }}
                                  >
                                    APPLY
                                  </button>
                                </div>
                                <div className="flex items-center justify-between text-xs text-gray-500 mt-2">
                                  <span>Expiry: {formattedExpiryDate}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <Lottie
              animationData={emptyCart}
              loop={true}
              style={{
                width: "20rem",
                height: "20rem",
              }}
              className="w-[20rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
            />
            <p className="text-red-400 text-lg sm:text-[0.9rem]">
              No items in your cart
            </p>
          </>
        )}
      </div>
    );
  }
};

export default ShoppingCart;
