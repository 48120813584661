import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

interface ProductState {
  allProduct: any[]; // Specify that allProduct is an array of Product
  latestProducts:any[];
  recommendedProducts:any[];
  trendingProducts:any[];
  totalPages: number;
  page: number;
  totalItems: number;
  limitForProduct: number;
  isLoading: boolean;
  filters: any;
  selectedFilter: any;
  metaInfo: any;
  setMessage:string;
  featureImages:[];
  bannerImage:string;
  categoryDescription:string;
}

// Define the initial state
const initialState: ProductState = {
  selectedFilter: [],
  allProduct: [],
  latestProducts:[],
  trendingProducts:[],
  recommendedProducts:[],
  totalPages: 0,
  page: 1,
  totalItems: 0,
  limitForProduct: 12,
  isLoading: false,
  filters: {},
  metaInfo: {},
  setMessage:"",
  featureImages:[],
  bannerImage:"",
  categoryDescription:""
};




const allProductSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateSelectedFilter: (state, action) => {
      const { filterField, filterValue } = action.payload;
      const existingFilter = state.selectedFilter.find(
        (filter: any) => filter[filterField]
      );

      if (existingFilter) {
        const valueIndex = existingFilter[filterField].indexOf(filterValue);

        if (valueIndex > -1) {
          existingFilter[filterField].splice(valueIndex, 1);

          if (existingFilter[filterField].length === 0) {
            state.selectedFilter = state.selectedFilter.filter(
              (filter: any) => filter !== existingFilter
            );
          }
        } else {
          existingFilter[filterField].push(filterValue);
        }
      } else {
        state.selectedFilter.push({ [filterField]: [filterValue] });
      }
    }, // Action to set all products
    
    saveLatestProduct:(state,action)=>{
      state.latestProducts=action.payload
    },
    saveRecommendedProduct:(state,action)=>{
      state.recommendedProducts=action.payload
    },
    saveTrendingProducts:(state,action)=>{
      state.trendingProducts=action.payload
    },
    saveProductLists:(state,action)=>{
      state.allProduct=action.payload.products
      state.totalPages = action.payload.totalPages;
      state.totalItems = action.payload.totalItems;
      state.page = action.payload.page;
      state.metaInfo = action.payload.metaInfo;
      state.categoryDescription=action.payload.categoryDescription;
      state.isLoading = false;
      state.bannerImage=action.payload.bannerImage
    },
    resetProductList: (state) => {
      state.allProduct = [];
      state.latestProducts = [];
      state.recommendedProducts = [];
      state.trendingProducts = [];
      state.totalPages = 0;
      state.totalItems = 0;
      state.page = 1;
    },
    
    saveFormattedBrands:(state,action)=>{
      state.isLoading = true;
      state.filters=action.payload
      state.isLoading = false
    },
    saveFormattedCategories:(state,action)=>{
      state.isLoading = true;
      state.filters=action.payload;
      state.isLoading =false
    },
    saveFormattedSubcategories:(state,action)=>{
      state.isLoading = true;
      state.filters=action.payload
      state.isLoading = false
    },
    setErrorMessage:(state,action)=>{
      state.setMessage=action.payload
    },
    saveFeatureImages:(state,action)=>{

      state.featureImages=action.payload
    },
    saveBannerImage:(state,action)=>{
      state.bannerImage=action.payload
    }
  },

});


// Export the reducer
export default allProductSlice.reducer;

export const {saveBannerImage,resetProductList, saveFeatureImages,setErrorMessage, updateSelectedFilter ,saveLatestProduct,saveRecommendedProduct,saveTrendingProducts, saveProductLists,saveFormattedBrands,saveFormattedCategories,saveFormattedSubcategories} = allProductSlice.actions;

export const filters = (state: RootState) => state.product.filters;

export const metaInfo = (state: RootState) => state.product.metaInfo;

export const selectedFilter = (state: RootState) =>
  state.product.selectedFilter;

export const totalItemForProduct = (state: RootState) => {
  return state.product.totalItems;
};
export const totalPageForProduct = (state: RootState) => {
  return state.product.totalPages;
};
export const product = (state: RootState) => {
  return state.product.allProduct;
};

export const isLoadingInProduct = (state: RootState) => {
  return state.product.isLoading;
};

export const page = (state: RootState) => {
  return state.product.page;
};

export const isLoadingForProduct = (state: RootState) => {
  return state.product.isLoading;
};
