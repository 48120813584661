import React, { useEffect, useState } from "react";
import {  GetRequest } from "../../../../api/Request";
import { useSelector } from "react-redux";



// Function to render stars based on rating
const renderStars = (rating: number) => {
  const fullStars = Math.floor(rating);
  const totalStars = 5;

  return (
    <span>
      {/* Render filled stars */}
      {[...Array(fullStars)]?.map((_, index) => (
        <span key={index} style={{ color: "#FFD700", fontSize: "20px" }}>★</span> // Filled star
      ))}
      {/* Render outlined stars for remaining */}
      {[...Array(totalStars - fullStars)]?.map((_, index) => (
        <span key={index} style={{ color: "#DDDDDD", fontSize: "20px" }}>☆</span> // Outlined star
      ))}
    </span>
  );
};



export const Reviews = (props: any) => {
  const { product } = props;
  const { user } = useSelector((state: any) => state.user);
  const [reviews, setReviews] = useState<any[]>([]);
  const [avgRating, setAvgRating] = useState(0);
  const [reviewId, setReviewId] = useState('');
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        if(!product._id)
          return;
        const response = await GetRequest(`/getAllProductReview/${product._id}`);
        const fetchedReviews = Array.isArray(response.data.reviews) ? response.data.reviews : [];

        // Set reviews state
        setReviews(fetchedReviews);

        // Calculate average rating
        const totalRating = fetchedReviews.reduce((acc: number, review: any) => acc + Number(review.rating), 0);
        const average = fetchedReviews.length > 0 ? totalRating / fetchedReviews.length : 0;
        setAvgRating(average);

        // Find the review for the current user
        const userReview = fetchedReviews.find((review: any) => review?.userId?._id === user?._id);
        if (userReview) {
          setReviewId(userReview._id); // Set the reviewId of the current user's review
        }

      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    fetchReviews();
  }, [product?._id, user?._id]);

  
  return (
<div className="p-6 relative border border-gray-200 rounded-lg shadow-sm mt-5 bg-white">
  {/* Header Section */}
  <div className="flex justify-between items-center mb-6 sm:flex-col sm:items-start">
    <h2 className="text-2xl font-bold text-red-900 sm:text-lg">Customer Ratings</h2>
    {/* Button to write a review (Uncomment if needed) */}
    {/* <Link to={`/review?productId=${product._id}&reviewId=${reviewId}`} className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition text-sm font-medium shadow-sm sm:w-full text-center">
      Write a Review
    </Link> */}
  </div>

  {/* Average Rating */}
  <div className="flex items-center mb-6 bg-gray-50 p-4 rounded-lg sm:flex-col sm:items-start sm:gap-2">
    <span className="text-lg font-semibold text-gray-800 sm:text-base">Average Rating:</span>
    <span className="ml-3 text-xl font-bold text-gray-900 sm:ml-0 sm:text-lg">{avgRating?.toFixed(1)}</span>
    <span className="ml-1 text-gray-500">/ 5</span>
    <div className="ml-4 sm:ml-0">{renderStars(avgRating)}</div>
  </div>

  {/* Individual Reviews */}
  {reviews?.length > 0 ? (
    reviews.map((review) => (
      <div key={review?._id} className="my-6 pb-6 border-b border-gray-200 last:border-b-0">
        {/* Rating Display */}
        <div className="flex items-center mb-3 sm:flex-col sm:items-start">
          <div className="flex items-center">{renderStars(Number(review?.rating))}</div>
          <span className="ml-2 text-sm font-medium text-gray-600 sm:ml-0">{review?.rating} / 5</span>
        </div>

        {/* Reviewer Name */}
        <div className="text-sm text-gray-500 mb-2">
          By <span className="font-medium text-gray-700">{review?.userId?.username}</span>
        </div>

        {/* Review Comment */}
        <p className="text-gray-700 leading-relaxed sm:text-sm">{review?.comment}</p>

        {/* Review Images */}
        {review?.images?.length > 0 && (
          <div className="flex flex-wrap gap-3 mt-4">
            {review.images.map((image: any, index: number) => (
              <img
                key={index}
                src={image.url}
                alt={`Review ${index}`}
                className="h-20 w-20 object-cover rounded-md border border-gray-200 hover:scale-105 transition-transform sm:h-16 sm:w-16"
              />
            ))}
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex gap-3 mt-4 sm:flex-wrap">
          <button className="flex items-center text-xs bg-gray-100 hover:bg-gray-200 text-gray-800 py-1 px-3 rounded-full transition focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 sm:text-[10px]">
            <span className="mr-1">★</span> Helpful
          </button>
          <button className="flex items-center text-xs bg-gray-100 hover:bg-gray-200 text-gray-800 py-1 px-3 rounded-full transition focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 sm:text-[10px]">
            <span className="mr-1">☆</span> Report
          </button>
        </div>
      </div>
    ))
  ) : (
    <p className="text-gray-500 text-center py-4 sm:text-sm">No reviews available yet.</p>
  )}
</div>

  );

};
