import { FaChevronRight } from "react-icons/fa"

const MainSection=({heading}:any)=>{
    return<>
    <div className="flex flex-col w-full h-full p-4">
        <div className="flex justify-between p-4 ">
            <div className="left ">
                <h2 className="text-4xl font-bold font-sans text-rose-700 ">{heading}</h2>

            </div>
            
        </div>
  
    </div>
    </>
}

export default MainSection