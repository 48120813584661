import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";

const Category = (props: any) => {
  let { allCategory, setShowCategory, isLoadingForCategory } = props;
  const categoryRef = useRef<any>(null);
  const [selectedIndexOfCategory, setSelectedIndexOfCategory] = useState<number>(0);

  const handleClickOutside = (event: { target: any }) => {
    if (categoryRef.current && !categoryRef.current.contains(event.target)) {
      setShowCategory(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section ref={categoryRef} className="rounded-lg relative bg-white flex border border-gray-300 shadow-lg">
      {/* Category List */}
      <div className="w-[250px] border-r-2 border-purple-500 overflow-y-auto max-h-[500px]">
        <p className="p-4 font-lighter border-b text-gray-700 flex items-center gap-2">
          <span className="text-purple-600"><img src="https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741072981929_1741072981919.webp" className="w-[20px] h-[20px]" alt="" /></span> SHOP BY CATEGORIES
        </p>
        {allCategory.length > 0 &&
          allCategory.map((category: any, index: number) => (
            <div
              key={index}
              className={`px-4 py-3 cursor-pointer flex justify-between items-center hover:bg-gray-100 transition border border-gray-300 mt-[1px] border-l-4 border-l-purple-600 ${selectedIndexOfCategory === index ? "bg-gray-100" : ""
                }`}
              onMouseEnter={() => setSelectedIndexOfCategory(index)}
            >

              <Link
                to={`category/${category.category.replace(/\s+/g, "-")}`}
                className="text-sm font-medium text-gray-700"
                onClick={() => setShowCategory(false)}
              >
                {category.category}
              </Link>
              {category.subCategories.length > 0 && <RiArrowRightSLine className="text-gray-500" />}
            </div>
          ))}
      </div>

      {/* Subcategory List */}
      <div className="w-[250px] p-2">
        {allCategory[selectedIndexOfCategory]?.subCategories.length > 0 && (
          <>
            <h4 className="font-lighter text-gray-800 mb-3 border-b-2 border-purple-400 pb-2">
              {allCategory[selectedIndexOfCategory].category}
            </h4>
            <ul className="space-y-2">
              {allCategory[selectedIndexOfCategory].subCategories.map((subcat: any, subcatIndex: number) => (
                <li key={subcatIndex}>
                  <Link
                    to={`subcategory/${subcat.name.replace(/\s+/g, "-")}?sub-category-id=${subcat._id}&page=1`}
                    className="text-sm text-gray-700 hover:text-purple-600 transition"
                    onClick={() => setShowCategory(false)}
                  >
                    &#8226; {subcat.name}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </section>
  );
};

export default Category;
