import React, { useState, useEffect } from "react";
import { asyncVerifyGSTInfo } from "../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { showSuccessToast } from "../../utils/toast";

interface GSTFormProps {
  setIsGSTInvoice: (value: boolean) => void;
  setGSTNumber: (value: string) => void;
}

const GSTForm = ({ setIsGSTInvoice, setGSTNumber }: GSTFormProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // For error display
  const { companyGSTInfo } = useSelector((state: RootState) => state.user);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setIsModalOpen(true);
    } else {
      // If unchecked, reset GST info in parent
      setIsGSTInvoice(false);
      setGSTNumber("");
      setGstNumber(""); // Clear local GST number
      setErrorMessage(null); // Clear any error message
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null); // Clear previous errors
    try {
      const response = await dispatch(asyncVerifyGSTInfo({ gstNumber }));
      // If no error is thrown, assume success
      showSuccessToast(response?.message);
      setIsModalOpen(false); // Close modal only on success
    } catch (error: any) {
      // Handle error explicitly
      setErrorMessage(
        error.response?.data?.message ||
          "Failed to verify GST. Please try again."
      );
    }
  };

  // Sync GST info with parent only when companyGSTInfo is available and checkbox is checked
  useEffect(() => {
    if (companyGSTInfo && isChecked) {
      setIsGSTInvoice(true);
      setGSTNumber(gstNumber);
    }
  }, [companyGSTInfo, isChecked, setIsGSTInvoice, setGSTNumber, gstNumber]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsChecked(false); // Uncheck the checkbox
    setIsGSTInvoice(false);
    setGSTNumber("");
    setGstNumber(""); // Clear local GST number
    setErrorMessage(null); // Clear any error message
  };

  return (
    <div className="w-full max-w-md">
      <div className="flex items-center gap-3 relative">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="w-3 h-3 text-teal-600 rounded focus:ring-2 focus:ring-teal-500 cursor-pointer"
        />
        <p className="text-[14px] font-semibold text-gray-900">
          Use GST Invoice
        </p>
      </div>

      {/* Show company name only when companyGSTInfo exists and isChecked is true */}
      {isChecked && companyGSTInfo && (
        <div className="mt-3 p-3 bg-purple-100 rounded-lg border border-purple-200 shadow-sm">
          <div className="flex items-center gap-2">
            <svg
              className="w-4 h-4 text-teal-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-sm text-gray-700">
              <span className="font-medium text-teal-800">Company:</span>{" "}
              <span className="text-xs">{companyGSTInfo}</span>
            </p>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed z-[10] inset-0 flex items-center justify-center bg-gray-900 bg-opacity-60 backdrop-blur-sm">
          <div className="bg-white p-6 rounded-xl shadow-2xl w-full max-w-md mx-4 transform transition-all duration-300 scale-100">
            <h2 className="text-xl font-semibold text-teal-900 mb-4">
              Enter GST Information
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="gstNumber"
                  className="block mb-1 text-sm font-medium text-gray-700"
                >
                  GST Number
                </label>
                <input
                  type="text"
                  id="gstNumber"
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                  className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-200 bg-gray-50 text-sm placeholder-gray-400"
                  placeholder="Enter your GST number"
                  required
                />
                {errorMessage && (
                  <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
                )}
              </div>
              <div className="flex gap-3">
                <button
                  type="submit"
                  className="flex-1 py-2.5 bg-[#3F0E9E] text-white rounded-lg focus:ring-2 focus:ring-teal-300 transition duration-200 text-sm font-medium shadow-sm"
                >
                  Verify GST
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="flex-1 py-2.5 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 focus:ring-2 focus:ring-gray-200 transition duration-200 text-sm font-medium"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GSTForm;
