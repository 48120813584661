const DealsBanner = () => {
    return (
      <div className="mt-[40px] relative w-full bg-[#DEA37F] overflow-hidden flex items-center justify-evenly">
        <div className="w-full flex justify-evenly items-center h-full lg:flex-col">
          {/* Shopping Basket Section */}
          <div className="flex items-start w-[20%] h-full justify-start lg:w-full">
            <div className="relative  h-44">
              <img 
                src="/cayrostore/last_banner_1.webp"
                alt="Shopping basket" 
                className="w-full h-full object-contain"
              />
            </div>
          </div>
  
          {/* Text Content Section */}
          <div className="flex flex-col items-center text-center w-[40%] lg:w-full">
            <h1 className="text-3xl font-semibold text-red-600 mb-2 tracking-wider lg:text-[25px]">
              Deals Jo Dil Jeet Le!
            </h1>
            <p className=" text-gray-800 tracking-wider text-2xl lg:text-xl">
              Dil Se Khareedo, Kyunki Har Khushi Ke Liye Koi Na Koi Gift Cayroshop Se Hi Aata Hai!
            </p>
          </div>
  
          {/* Discount Voucher Section */}
          <div className="flex items-end h-full  w-[30%] justify-end lg:w-full">
            <div className="relative w-full h-54 ">
              <img 
                src="/cayrostore/coupon.webp"
                alt="Discount voucher" 
                className="w-full h-full object-contain lg:w-[300px]"
              />
            </div>
          </div>
        </div>

      </div>
    );
  };
  
  export default DealsBanner;
  