import { useDispatch, useSelector } from "react-redux";
import AccountSidebar from "../SideBar";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeliveryAddress from "../../deliveryAddress/DeliveryAddress";
import DeleteDialogue from "../../deleteDialogue/DeleteDialogue";
import { PostRequest } from "../../../api/Request";
import { showErrorToast } from "../../../utils/toast";
import { AppDispatch, RootState } from "../../../store/store";
import { Helmet } from "react-helmet";
import { setUser } from "../../../slice/UserSlice";
import { asyncDeleteAddress, asyncFetchUserProfile, asyncUpdateAddress } from "../../../actions/userAction";
import UpdateAddress from "../UpdateAddressForm/UpdateAddress";

const AddressList = (props: any) => {
  let { checkoutPage, heading } = props;
  const dispatch=useDispatch<AppDispatch>()
  const userDetail: any = useSelector((state:RootState)=>state.user.user);
  const [showAddressForm, setShowAddAddress] = useState<Boolean>(false);
  const [isLoadingForDelete, setIsLoadingForDelete] = useState<boolean>(false);
  const [isLoadingForUpdate, setIsLoadingForUpdate] = useState<boolean>(false);
  const [indexForDelete, setIndexForDelete] = useState<any>();
  const [indexForUpdate,setIndexForUpdate]=useState<any>();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<any>(null);
  const userIsLoggedIn = useSelector((state:RootState)=>state.user.isLoggedIn);
  const navigate = useNavigate();
  const isLoadingForUser = useSelector((state:RootState)=>state.user.loading);

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, [userIsLoggedIn, isLoadingForUser,navigate]);

  useEffect(() => {
    if (selectedAddressIndex !== null) {
      localStorage.setItem(
        "selectedAddressIndex",
        selectedAddressIndex.toString()
      );
    } else {
      localStorage.removeItem("selectedAddressIndex"); // Clear if no address is selected
    }
  }, [selectedAddressIndex]);

  const handleSelectAddress = async (index: number, value: string) => {
    // Prepare the payload based on the selected address type
    let payload =
      value === "shipping"
        ? { shipping: true, index }
        : { billing: true, index };

    try {
      // Send the request to update the default address
      const response = await PostRequest("/update-default-address", payload);

      // Check if the response indicates success
      if (response.status === 200) {
        // Create a copy of current addresses
        const updatedAddresses: any = [...userDetail.addresses];

        // Reset all addresses to not be default for shipping or billing
        updatedAddresses.forEach((address: any, idx: any) => {
          if (value === "shipping") {
            updatedAddresses[idx] = {
              ...address,
              shipping: idx === index, // Set current index as true, others as false
            };
          } else if (value === "billing") {
            updatedAddresses[idx] = {
              ...address,
              billing: idx === index, // Set current index as true, others as false
            };
          }
        });

        // Dispatch updated user details with modified addresses
        dispatch(
          setUser({
            _id: userDetail._id || "",
            username: userDetail.username || "",
            number: userDetail.number || "",
            gender: userDetail.gender || "",
            email: userDetail.email || "",
            addresses: updatedAddresses || [],
            userIsLoggedIn: true,
          })

        );
        dispatch(asyncFetchUserProfile())
      }
      
    } catch (error) {
      // Handle errors and show error toast
      showErrorToast("Failed to update address");
    }
  };

  const AddAddressForm = () => {
    setShowAddAddress(() => true);
  };

  const ShowDeleteAddress = (index: number) => {
    setIndexForDelete(() => index);
  };
  const ShowAddressUpdate=(index:number)=>{
    setIndexForUpdate(()=>index)
  }

  const handleDeleteAddress = async () => {
    setIsLoadingForDelete(() => true);
    try {
      dispatch(asyncDeleteAddress(indexForDelete))
        setIndexForDelete(null); // Reset index for delete
    } catch (error) {
      showErrorToast("Error in Deleting the address");
    }
    setIsLoadingForDelete(() => false);
  };

  const handleUpdateAddress = async (index: number, updatedAddress: any) => {
      await dispatch(asyncUpdateAddress(indexForUpdate,updatedAddress))  
  };


  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div
        className={
          !checkoutPage
            ? "flex mt-[8rem] items-start justify-center flex-wrap mb-[1rem] sm:px-[0.7rem]"
            : "flex  items-start justify-center flex-wrap sm:px-[0.7rem]"
        }
      >
        {!checkoutPage && (
          <div className="basis-[20%] sm:hidden lg:basis-[100%] mt-[1rem] border-r-[1px] border-r-[#ddd]">
            <AccountSidebar />
          </div>
        )}
        {showAddressForm && (
          <DeliveryAddress
            // heading={''}
            showAddressForm={showAddressForm}
            setShowAddAddress={setShowAddAddress}
          />
        )}

        {indexForDelete != null ? (
          <DeleteDialogue
            handleDeleteAddress={handleDeleteAddress}
            setIndexForDelete={setIndexForDelete}
            isLoadingForDelete={isLoadingForDelete}
          />
        ) : null}

     {
          indexForUpdate != null?(
            <UpdateAddress handleUpdateAddress={handleUpdateAddress}
            setIndexForUpdate={setIndexForUpdate}
          />
          ):null
        } 
      

        <div
          className={
            checkoutPage
              ? "flex-1  sm:p-0 basis-[50%] md:basis-[90%] md:mt-0 w-full"
              : "flex-1 p-4 sm:p-0 basis-[50%] md:basis-[90%] md:mt-0 w-full"
          }
        >
          <div
            className={
              !checkoutPage
                ? "w-[90%] h-full bg-white rounded p-2 mx-auto sm:w-[93%] sm:p-0"
                : " bg-white  mx-auto  "
            }
          >
            {heading !== "Billing Address" && (
              <div className="flex justify-between items-start">
                <h1 className="font-semibold text-xl  mb-3 sm:mb-3">
                  Your Addresses
                </h1>
                <span
                  onClick={AddAddressForm}
                  className="bg-primary cursor-pointer text-white h-[30px] w-[30px] rounded-[50%] flex justify-center items-center text-xl"
                >
                  +
                </span>
              </div>
            )}

            {userDetail?.addresses?.length > 0 && (
              <div className="mb-2">
                <h3 className="font-semibold text-lg md:text-[16px]">{heading}</h3>
              </div>
            )}

            <ul>
              {userDetail.addresses?.length > 0 ? (
                userDetail.addresses.map((address: any, index: number) => {
                  return (
                    <div key={index}>
                      <li className="items-center">
                        {checkoutPage && (
                          <input
                            type="checkbox"
                            checked={
                              heading === "Shipping Address"
                                ? address.shipping
                                : address.billing
                            }
                            onChange={() =>
                              handleSelectAddress(
                                index,
                                heading === "Shipping Address"
                                  ? "shipping"
                                  : "billing"
                              )
                            } // Update selection on checkbox change
                            className="mr-2 cursor-pointer"
                          />
                        )}
                        <span>{index + 1}. </span>
                        <span className="font-semibold text-[12px]">
                          {address?.name + ", "}
                        </span>
                        <span className="font-semibold text-[12px]">
                          {address?.mobileNumber + ", "}
                        </span>
                        <span className="text-[12px]">{address?.address1 + ", "} </span>
                        <span className="text-[12px]">{address?.address2 + ", "} </span>
                        <span className="text-[12px]">{address?.city + ", "} </span>
                        <span className="text-[12px]">{address?.state + ", "} </span>
                        <span className="text-[12px]">{address?.postalCode + ", "} </span>
                        <span className="text-[12px]">{address?.country + ", "} </span>
                        {/* <p className="text-blue-500 cursor-pointer text-sm inline-block">
                        {"Edit Address" + ",  "}
                      </p> */}
                      <div className="flex gap-3">
                      <p
                          onClick={() => ShowDeleteAddress(index)}
                          className="text-blue-500 cursor-pointer text-[12px] inline-block"
                        >
                          Delete Address
                        </p>
                        <p onClick={() => setIndexForUpdate(index)} className="text-blue-500 cursor-pointer text-[12px] inline-block">Edit Address</p>

                      </div>
                      
                      </li>
                      <hr className="my-[0.9rem]" />
                    </div>
                  );
                })
              ) : (
                <p className="text-center text-red-500 mt-[1rem]">
                  No Addresses Found
                </p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressList;
