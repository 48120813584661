import { FaFileInvoice, FaShippingFast, FaShieldAlt, FaCreditCard, FaBoxOpen } from "react-icons/fa";
import Features from "./Features";

const BottomSection = (props: any) => {
    const { product } = props;

    // Extract Key Features Properly
    const keyFeatures = product?.specification?.find((spec: any) => spec?.heading);
    const benefits = [
        { icon: FaFileInvoice, text: "GST Invoice Available" },
        { icon: FaShippingFast, text: "Sign up for free shipping" },
        { icon: FaShieldAlt, text: "Secure payment" },
        { icon: FaCreditCard, text: "EMI Available" },
        { icon: FaBoxOpen, text: "COD available under ₹10000" },
    ];
    return (
        <>
            <div className="p-4 rounded-lg shadow-md w-full">
                <p className="text-red-900 font-semibold text-lg">CAYRO BENEFITS</p>

                {/* Scrollable container on small screens */}
                <div className="mt-4 flex gap-[20px] w-full items-center justify-around sm:overflow-scroll"
                    style={{ display: 'flex' }} // Ensuring horizontal scroll
                >
                    {benefits.map(({ icon: Icon, text }, index) => (
                        <div
                            key={index}
                            className=" w-[400px] h-[100px] border rounded-md p-2 flex flex-col items-center justify-evenly text-center hover:shadow-md transition-shadow"
                        >
                            <Icon size={20} className="text-red-900 mb-2" />
                            <p className="uppercase text-[10px] font-medium">{text}</p>
                        </div>
                    ))}
                </div>
            </div>


            <div className="flex gap-4 w-full mt-5 md:flex-col">
                {/* Left Section */}
                <div className="p-4 w-1/3 md:w-full bg-gradient-to-b from-yellow-50 to-gray-100 flex items-center justify-center border rounded-md shadow">
                    <p className="text-lg font-semibold text-red-900 text-center uppercase">
                        Best Price, Direct <br />
                        From Our <br />
                        Warehouse to You
                    </p>
                </div>

                {/* Right Section */}
                {keyFeatures?.feature?.length > 0 && (
                    <div className="w-2/3 md:w-full border border-gray-300 p-4 rounded-md shadow bg-white">
                        <p className="text-red-900 font-semibold sm:text-[13px]">KEY FEATURES</p>
                        <ul className="text-sm text-gray-800 mt-2 space-y-1 sm:text-[11px]">
                            {keyFeatures.feature.map((item: any, index: number) => (
                                <li key={index}>• <strong>{item.label}:</strong> {item.value}</li>
                            ))}
                        </ul>
                    </div>
                )}

            </div>

            <Features product={product} />
        </>
    );
};

export default BottomSection;
