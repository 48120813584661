import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  token: any;
  address: any;
  user: {
    username: string;
    _id: string;
    number: string;
    gender: string;
    email: string;
    addresses: [];
  } | null;
  gstInformation: [];
  cart: [];
  cartLength: 0;
  orders: any[];
  totalOrders: number;
  totalPages: number,
  isLoggedIn: boolean;  // <-- Add isLoggedIn property
  loading: boolean;
  error: string | null;
  companyGSTInfo:string;
}

const initialState: UserState = {
  token: "",
  user: {
    _id: "",
    username: "",
    number: "",
    gender: "",
    email: "",
    addresses: [],
  },
  cart: [],
  orders: [],
  totalOrders: 0,
  totalPages: 1,
  cartLength: 0,
  isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn") || "false"), // <-- Set isLoggedIn based on localStorage
  loading: false,
  error: null,
  address: undefined,
  gstInformation: [],
  companyGSTInfo:""
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserToken: (state, action) => {
      localStorage.setItem("userToken", action.payload);
      state.token = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.isLoggedIn = false; // <-- Set isLoggedIn to false
      localStorage.removeItem("userToken");
      localStorage.setItem("isLoggedIn", "false");
      localStorage.removeItem("cartLength");
    },
    setUser: (
      state,
      action: PayloadAction<{
        _id: string;
        username: string;
        number: string;
        gender: string;
        email: string;
        userIsLoggedIn: boolean;
        addresses: [];
      }>
    ) => {
      state.user = action.payload;
      state.isLoggedIn = action.payload.userIsLoggedIn; // <-- Set isLoggedIn based on action
    },
   
    setGSTInformation: (state, action: PayloadAction<any>) => {
      state.gstInformation = action.payload;
    },
    saveOrders: (state, action: PayloadAction<any>) => {
      state.orders = action.payload.orders;
      state.totalOrders = action.payload.totalOrders
      state.totalPages = action.payload.totalPages
    },
    saveCompanyInfo:(state,action:PayloadAction<any>)=>{
      state.companyGSTInfo=action.payload
    }
  },
});

export const {saveCompanyInfo, logout, setUser, setGSTInformation, saveUserToken, saveOrders } = userSlice.actions;

export default userSlice.reducer;
